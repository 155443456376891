import styled from "styled-components";
import {
  BaseTypoCSS,
  BaseTypoProps,
} from "./BaseTypo";

const Caption2 = styled.p<BaseTypoProps>`
  ${BaseTypoCSS({
    fontFamily: "NeueHaasUnica",
  })}
  font-style: normal;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0;
  font-weight: normal;
`;

export default Caption2;
