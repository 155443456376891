import React from "react";
import styled from "styled-components";
import Wrapper from "../../DS/Wrapper/Wrapper";
import LinkCard from "../LinkCard/LinkCard";
import TriColorDivider from "../TriColorDivider/TriColorDivider";
import aarp from "../../assets/icons/helpful/aarp.png";
import albama_gov from "../../assets/icons/helpful/alabama-gov.png";

import aea from "../../assets/icons/helpful/aea.png";
import albama from "../../assets/icons/helpful/albama.png";
import ahb from "../../assets/icons/helpful/ahb.png";
import ash from "../../assets/icons/helpful/ash.png";
import amba from "../../assets/icons/helpful/amba.jpg";
import nea from "../../assets/icons/helpful/nea.png";
import american from "../../assets/icons/helpful/american.png";

import neab from "../../assets/icons/helpful/neab.png";

import tal from "../../assets/icons/helpful/tal.png";
import trsa from "../../assets/icons/helpful/trsa.png";
import LightDivider from "../LightDivider/LightDivider";
import EmailSubscribe from "../EmailSubscribe/EmailSubscribe";
import H2 from "../../DS/Typo/H2";
import RLinkCard from "../RLinkCard/RLinkCard";

const HelpfulContainer = styled.div``;

const helpfulData = [
  {
    text: "AARP",
    icon: aarp,
    link: "//www.aarp.org",
  },
  {
    text: "AARP Driver Safety Program",
    icon: aarp,
    link: "//www.aarp.org/drive",
  },
  // {
  //   text: "AEA Access Card",
  //   icon: aea,
  //   link: "//www.myaea.org/Benifits/Accesscard.html",
  // },
  {
    text: "Alabama Education Association",
    icon: aea,
    link: "//www.myaea.org/",
  },
  {
    text: "Alabama Governor",
    icon: albama,
    link: "//governor.alabama.gov",
  },
  {
    text: "American Hearing Benefits",
    icon: ahb,
    link: "//www.americanhearingbenefits.com",
  },
  {
    text: "AMBA/AERA Benefits",
    icon: amba,
    link: "http://www.amba.info/aera/",
  },
  {
    text: "National Education Association",
    icon: nea,
    link: "//www.nea.org",
  },
  // {
  //   text: "National Retired Teachers Association",
  //   icon: aarp,
  //   link: "//www.aarp.org/nrta",
  // },
  {
    text: "NEA Member Benefits",
    icon: neab,
    link: "//www.neamb.com/",
  },
  {
    text: "National Retired Teachers Association",
    icon: aarp,
    link: "http://LiveAndLearn.aarp.org",
  },
  {
    text: "The Alabama Legislature",
    icon: tal,
    link: "http://www.legislature.state.al.us/aliswww/default.aspx",
  },
  {
    text: "The Retirement Systems of Alabama",
    icon: trsa,
    link: "https://www.rsa-al.gov/",
  },
  {
    text: "",
    icon: "",
    link: "",
    hide: true,
  },
];

const resourceData: {
  text: string;
  icon: string;
  link: string;
  phone?: string;
}[] = [
  {
    text: "Alabama Education Association",
    icon: aea,
    link: "//www.myaea.org",
    phone: "334-834-9790",
  },
  {
    text: "Retirement Systems of Alabama",
    icon: trsa,
    link: "//www.rsa-al.gov",
    phone: "1-877-517-0020",
  },
  {
    text: "PEEHIP",
    icon: trsa,
    link: "//www.rsa-al.gov/peehip/",
    phone: "1-877-517-0020",
  },
  {
    text: "Alabama State House",
    icon: ash,
    link: "http://www.legislature.state.al.us/aliswww/ISD/Splash_House.aspx",
    phone: "334-242-7095",
  },
  {
    text: "Official Website of Alabama",
    icon: albama_gov,
    link: "//www.alabama.gov",
  },
  {
    text: "National Education Association",
    icon: american,
    link: "//www.nea.org",
    phone: "202-833-4000",
  },
  {
    text: "AARP-Alabama",
    icon: aarp,
    link: "https://states.aarp.org",
    phone: "866-542-8167",
  },
];

const Helpful = () => {
  return (
    <HelpfulContainer>
      <TriColorDivider />
      <Wrapper type="m" marginTop="60px">
        <H2>Helpful Links</H2>
      </Wrapper>
      <Wrapper
        type="m"
        marginTop="20px"
        display="flex"
        justifyContent="space-between"
        style={{
          flexWrap: "wrap",
        }}
      >
        {helpfulData.map((link, index) => {
          return (
            <LinkCard
              key={index}
              text={link.text}
              icon={link.icon}
              link={link.link}
              hide={link.hide}
            />
          );
        })}
      </Wrapper>
      <Wrapper type="m" marginTop="60px">
        <H2>Resource Links</H2>
      </Wrapper>
      <Wrapper
        type="m"
        marginTop="20px"
        display="flex"
        justifyContent="space-between"
        style={{
          flexWrap: "wrap",
        }}
        marginBottom="100px"
      >
        {resourceData.map((link, index) => {
          return (
            <RLinkCard
              key={index}
              text={link.text}
              icon={link.icon}
              link={link.link}
              phone={link.phone}
            />
          );
        })}
      </Wrapper>
      <LightDivider />
      <EmailSubscribe />
      <Wrapper type="m" marginTop="80px" />
    </HelpfulContainer>
  );
};

export default Helpful;
