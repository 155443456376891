import React from "react";
import styled from "styled-components";
import Body1 from "../../DS/Typo/Body1";
import Wrapper from "../../DS/Wrapper/Wrapper";
import LegislatorCTA from "../LegislatorCTA/LegislatorCTA";
import TriColorDivider from "../TriColorDivider/TriColorDivider";
import mark from "../../assets/images/mark.png";
const MarkImg = styled.img`
  position: absolute;
   top: 60%;
    right: 0;
    width: 110px;
    
`;
const AdvocacyContainer = styled.div``;

const Advocacy = () => {
  return (
    <AdvocacyContainer>
      <TriColorDivider />
      <Wrapper
        type="m"
        marginTop="60px"
        marginBottom="40px"
      >
        <Body1>
          AERA works every day advocating on
          behalf of public education retirees. We
          are the only association in Alabama
          working to keep your hard-earned
          benefits and interests intact in your
          retirement years. In addition, AERA
          advocates for additional monetary
          increases for our members through
          legislative enacted bonus checks and
          COLA’s.
        </Body1>
        <Body1>
          AERA’s member involved Advocacy Network,
          Legislative Committee, Board of
          Director’s, and Lobby Team are working
          year-round on your behalf with the
          Alabama Legislature.
        </Body1>
        <Body1>
          AERA conducts valuable Advocacy Day
          events bringing our members to the
          Alabama State House to engage in
          face-to-face advocating efforts on a
          variety of retirement issues.AERA
          members are renowned for their political
          activism and advocating successes.
        </Body1>
      </Wrapper>
      <LegislatorCTA
        type="top"
        hideAdvocacy={true}
      />
       <MarkImg src={mark} alt="AERA" />
    </AdvocacyContainer>
  );
};

export default Advocacy;
