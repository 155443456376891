import { useScreenClass } from "@tinyd/usescreenclass";
import React from "react";
import styled from "styled-components";
import Button from "../../DS/Button/Button";
import SplitImg1 from "../../assets/images/split1.jpg";
import SplitImg2 from "../../assets/images/split2.jpg";
import Wrapper from "../../DS/Wrapper/Wrapper";
import mark from "../../assets/images/mark.png";
import { Fade } from "react-awesome-reveal";
import H3 from "../../DS/Typo/H3";
import H2 from "../../DS/Typo/H2";
import Body1 from "../../DS/Typo/Body1";
import HomeSplitImage from "../HomeSplitImage/HomeSplitImage";
import AboutCTA from "../AboutCTA/AboutCTA";
import JoinCTA from "../JoinCTA/JoinCTA";
import NewsRoomCTA from "../NewsRoomCTA/NewsRoomCTA";
import EmailSubscribe from "../EmailSubscribe/EmailSubscribe";
import UpComingEvents from "../UpComingEvents/UpComingEvents";
import LegislatorCTA from "../LegislatorCTA/LegislatorCTA";
import { Link } from "react-router-dom";
const MarkImg = styled.img`
  position: absolute;
  top: 8%;
  left: -20px;
  width: 150px;
`;
const HomeContainer = styled.div`
  margin-top: -25px;
  position: relative;
  overflow-x: hidden;
`;
const Home = () => {
  const screen = useScreenClass();
  const isSmall = ["md", "sm", "xs"].includes(
    screen
  );
  const width = isSmall
    ? "100%"
    : "calc(33% - 20px/3)";
  const marginBottom = isSmall ? "20px" : "0px";
  return (
    <HomeContainer>
      <Wrapper
        type="l"
        display="flex"
        justifyContent="space-between"
        flexDirection={isSmall ? "column" : "row"}
      >
        <Fade
          direction="up"
          style={{
            width,
            marginBottom,
          }}
          childStyle={{ width: "100%" }}
          duration={500}
          delay={300}
          triggerOnce
        >
          <Link to="/about/who_we_are">
            <Button
              shadow
              style={{ width: "100%" }}
            >
              Who We Are
            </Button>
          </Link>
          <Link to="/about/who_we_are#what_we_stand_for">
            <Button
              bgColor="blue"
              shadow
              style={{ width: "100%" }}
            >
              What We Stand For
            </Button>
          </Link>
          <Link to="/join">
            <Button
              bgColor="yellow"
              shadow
              style={{ width: "100%" }}
            >
              Become a Member
            </Button>
          </Link>
        </Fade>
      </Wrapper>
      <Wrapper type="m" marginTop="54px">
        <H3 fontColor="magenta">Who We Are</H3>
        <H2>The Voice for Education Retirees</H2>
        <Body1 marginTop="20px">
          AERA is the retired division of the
          Alabama Education Association (AEA). It
          is made up of 76 local units, including
          five active higher education units.
          These units are divided into ten
          districts. There is at least one local
          unit in each Alabama county. AERA works
          closely with NEA-Retired and is
          currently the third largest state
          retiree group in NEA-Retired. AERA is
          also a partner with the National Retired
          Teachers Association (NRTA), which is a
          division of AARP.
        </Body1>
        <Fade>
          <Link to="/about/who_we_are">
            <Button
              marginTop="24px"
              hoverColor="magenta"
            >
              Learn More
            </Button>
          </Link>
        </Fade>
      </Wrapper>
      <HomeSplitImage
        imgs={[SplitImg1, SplitImg2]}
      />
      <AboutCTA />
      <JoinCTA bgUrl={SplitImg2} />
      <NewsRoomCTA />
      <EmailSubscribe />
      <UpComingEvents />
      <LegislatorCTA backgroundColor="lightGray" />
      <MarkImg src={mark} alt="AERA" />
    </HomeContainer>
  );
};

export default Home;
