import { useScreenClass } from "@tinyd/usescreenclass";
import Color from "color";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Body1 from "../../DS/Typo/Body1";
import H4 from "../../DS/Typo/H4";
import { useSetSharedState } from "../../hooks/useSharedState/useSharedState";

const NewsCardContainer = styled.div<{
  bgUrl: string;
  size: number;
  h_size: number;
  gap: number;
}>`
  ${({ bgUrl, size, gap, h_size }) => `
    background-image: url(${bgUrl});
    width:${size}px;
    height:${h_size}px;
    margin-right: ${gap}px;
`}
  max-width:calc(100vw - 60px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  background-size: 170%;
  transition: all 0.3s ease;

  &:hover {
    background-size: 150%;
  }
`;
const NewsCardContainerGradient = styled.div<{
  size: number;
  h_size: number;
}>`
  ${({ size, h_size }) => `
    width:${size}px;
    height:${h_size}px;
`}
  flex: 1;
  padding: 0px 25px 100px 25px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background: linear-gradient(
    ${Color("#2b2b54")
        .rgb()
        .alpha(0.5)
        .toString()}
      0%,
    ${Color("#c82c51")
        .rgb()
        .alpha(0.5)
        .toString()}
      150%
  );
`;

const CustomH4 = styled(H4)`
  overflow: hidden;
  white-space: nowrap;
  width: calc(100% - 50px);
  margin: 0px 25px;
  text-overflow: ellipsis;
`;

const NewsCard: FC<{
  title: string;
  category: string;
  img: string;
  link: string;
  gap?: number;
  long?: boolean;
  singleOnMobile?: boolean;
}> = ({
  title,
  category,
  img,
  gap = 20,
  link,
  long = false,
  singleOnMobile = false,
}) => {
  const screen = useScreenClass();
  const isSmall = ["sm", "xs"].includes(screen);
  const setTitle =
    useSetSharedState("news_title");
  const setBanner = useSetSharedState(
    "news_banner"
  );
  const size =
    singleOnMobile && isSmall
      ? 302
      : (isSmall ? 224 : 440) * (long ? 2 : 1) +
        (long ? gap : 0);
  const h_size = isSmall ? 224 : 440;
  const _gap =
    singleOnMobile && isSmall ? 0 : gap;

  return (
    <Link
      to={link}
      onClick={() => {
        setTitle(title);
        setBanner(img);
      }}
    >
      <NewsCardContainer
        bgUrl={img}
        size={size}
        h_size={h_size}
        gap={_gap}
      >
        <NewsCardContainerGradient
          size={size}
          h_size={h_size}
          style={
            isSmall
              ? {
                  paddingBottom: 30,
                }
              : {}
          }
        >
          <Body1
            fontColor="white"
            marginLeft={isSmall ? 15 : 25}
            marginBottom={
              isSmall ? "10px" : "22px"
            }
          >
            {category}
          </Body1>
          <CustomH4
            fontColor="white"
            title={title.replace(
              "&#8230;",
              "..."
            )}
            style={
              isSmall
                ? {
                    margin: "0px 15px",
                    width: "calc(100% - 30px)",
                  }
                : {}
            }
          >
            {title.replace("&#8230;", "...")}
          </CustomH4>
        </NewsCardContainerGradient>
      </NewsCardContainer>
    </Link>
  );
};

export default NewsCard;
