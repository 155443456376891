import { Property } from "csstype";
import styled from "styled-components";
import DS, { DSWrapper } from "../DS";

export type WrapperProps = {
  type?: keyof DSWrapper;
  marginTop?: Property.MarginTop;
  marginBottom?: Property.MarginBottom;
  paddingLeft?: Property.PaddingLeft;
  paddingRight?: Property.PaddingRight;
  paddingTop?: Property.PaddingTop;
  paddingBottom?: Property.PaddingBottom;
  customPaddingSpacing?: number;
  noSmTriggers?: boolean;
  display?: Property.Display;
  flexDirection?: Property.FlexDirection;
  justifyContent?: Property.JustifyContent;
  alignItems?: Property.AlignItems;
  flex?: Property.Flex;
  height?: Property.Height;
};

const Wrapper = styled.div<WrapperProps>`
  ${({
    type = "m",
    marginTop = "0px",
    marginBottom = "0px",
    paddingLeft = "0px",
    paddingRight = "0px",
    paddingTop = "0px",
    paddingBottom = "0px",
    customPaddingSpacing = DS.wrapperInnerPadding,
    noSmTriggers = false,
    display,
    flexDirection,
    justifyContent,
    alignItems,
    flex,
    height,
  }) => `
        ${display ? `display:${display};` : ""}
        ${height ? `height:${height};` : ""}
        ${
          flexDirection
            ? `flex-direction:${flexDirection};`
            : ""
        }
        ${
          justifyContent
            ? `justify-content:${justifyContent};`
            : ""
        }
        ${
          alignItems
            ? `align-items:${alignItems};`
            : ""
        }
        ${flex ? `flex:${flex};` : ""}
        max-width:${DS.wrappers[type]}px;
        width:calc(100% - ${
          customPaddingSpacing * 2
        }px);
        margin:${marginTop} auto ${marginBottom} auto;
        padding:${paddingTop} ${
    paddingRight === "0px"
      ? `${customPaddingSpacing}px`
      : paddingRight
  } ${paddingBottom} ${
    paddingLeft === "0px"
      ? `${customPaddingSpacing}px`
      : paddingLeft
  };

        @media(min-width:${DS.wrappers[type]}px){
           max-width:${DS.wrappers[type]}px;
          
        }

        @media(max-width:${
          noSmTriggers
            ? 0
            : DS.wrapperInnerSmTriggerWidth
        }px){
           max-width:${DS.wrappers.sm}px;
        }
    `}
`;

export default Wrapper;
