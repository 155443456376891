import axios from "axios";
import { ENDPOINT } from "../utils/api";

export interface NewsType {
  date: string;
  title: Title;
  slug: string;
  acf: Acf;
}

interface Title {
  rendered: string;
}

interface Acf {
  news_banner: NewsBanner;
  category: Category;
}

interface NewsBanner {
  url: string;
}

interface Category {
  term_id: number;
  name: string;
  slug: string;
  term_group: number;
  term_taxonomy_id: number;
  taxonomy: string;
  description: string;
  parent: number;
  count: number;
  filter: string;
}

const getAllNews = (): Promise<NewsType[]> => {
  //get static data
  return new Promise((resolve, reject) => {
    axios
      .get<NewsType[]>(
        `${ENDPOINT}/wp/v2/news?_fields=title,slug,acf.category,acf.news_banner.url,date&per_page=100`
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};

export default getAllNews;
