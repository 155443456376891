import React from "react";
import styled from "styled-components";
import H2 from "../../DS/Typo/H2";
import Wrapper from "../../DS/Wrapper/Wrapper";
import bod from "../../assets/images/bod.jpg";
import InfoCard from "../InfoCard/InfoCard";
import EmailSubscribe from "../EmailSubscribe/EmailSubscribe";
import LightDivider from "../LightDivider/LightDivider";
import mark from "../../assets/images/mark.png";
const MarkImg = styled.img`
  position: absolute;
  top: 200%;
  right: 0;
  width: 110px;
`;
const BoardOfDirectorContainer = styled.div``;

const BODImg = styled.img`
  margin: 54px auto;
  max-width: 100%;
`;

const data = [
  {
    heading: "PRESIDENT",
    title: "Sarah Horton",
    phone: ["251-554-1120"],
    mail: "hort4032@bellsouth.net",
  },
  {
    heading: "Vice President",
    title: "Rex Cheatham",
    phone: ["256-565-2027"],
    mail: "rex.rexcheatham@yahoo.com",
  },
  {
    heading: "Treasurer",
    title: "Peggy Mobley",
    phone: ["334-312-3258"],
    mail: "phmobley46@gmail.com",
  },
  {
    heading: "Secretary",
    title: "Susan Helms",
    phone: ["334-347-7350", "334-389-0622"],
    mail: "shelms4@roadrunner.com",
  },
  {
    heading: "Member At Large, Leg.",
    title: "Alma Wyatt Jones",
    phone: ["205-345-5514", "205-242-4442"],
    mail: "awj5514@aol.com",
  },
  {
    heading: "Member At Large, Mbr.",
    title: "Vivian Crosby",
    phone: ["334-309-6209"],
    mail: "vivian.crosby@att.net",
  },
  {
    heading: "Immediate Past Pres. (solo term)",
    title: "John Paul Jones",
    phone: ["251-689-1126"],
    mail: "johnpaul.aera@gmail.com",
  },
  {
    heading: "Parliamentarian",
    title: "Teresa Noell",
    phone: ["256-236-5456", "256 225 5456"],
    mail: "tnoellaera@gmail.com",
  },
  {
    heading: "Board of Control",
    title: "Dave Wales, TRS-PEEHIP",
    phone: ["334-517-7176"],
    mail: "dave.wales@rsa-al.gov",
  },
  {
    heading: "AEA-AERA Coordinator",
    title: "Theron Stokes",
    phone: ["334-834-9790"],
    mail: "theron.stokes@alaedu.org",
  },
  {
    heading: "District 1 Director",
    title: "Melba Wiegand",
    phone: ["205-746-5288"],
    mail: "melbawiegand@gmail.com",
  },
  {
    heading: "District 2 Director",
    title: "Dr. Toni McGriff",
    phone: ["256-228-3520"],
    mail: "tonimcgriff@gmail.com",
  },
  {
    heading: "District 3 Director",
    title: "Cynthia Peebles",
    phone: ["205-792-0638"],
    mail: "cpeeblesretired@gmail.com",
  },
  {
    heading: "District 4 Director",
    title: "Brenda Lockhart",
    phone: ["205-841-3312", "205-601-0248"],
    mail: "brnlock8@aol.com",
  },
  {
    heading: "District 5 Director",
    title: "Pam Hendrick",
    phone: ["334-559-0462"],
    mail: "pamgregory@charter.net",
  },
  {
    heading: "District 6 Director",
    title: "Dorothy Irvin",
    phone: ["334-505-9280"],
    mail: "drthyirvin@gmail.com",
  },
  {
    heading: "District 7 Director",
    title: "Pauline George",
    phone: ["334-221-8186"],
    mail: "pwgeorgeala@gmail.com",
  },
  {
    heading: "District 8 Director",
    title: "Susan Fant",
    phone: ["251-367-1121"],
    mail: "Superli52@aol.com",
  },
  {
    heading: "District 9 Director",
    title: "Brenda Curry",
    phone: ["334-791-8303"],
    mail: "nalebmc@yahoo.com",
  },
  {
    heading: "District 10 Director",
    title: "David Johnson",
    phone: ["256-658-8433"],
    mail: "myaeanea@gmail.com",
  },
  {
    heading: "Executive Director",
    title: "Jill E. Jackson",
    phone: ["334-262-4177", "800-537- 6867"],
    mail: "jjackson@aerainc.org",
  },
];

const BoardOfDirector = () => {
  return (
    <BoardOfDirectorContainer>
      <Wrapper
        type="m"
        display="flex"
        alignItems="center"
        flexDirection="column"
      >
        <H2
          style={{
            textAlign: "center",
            maxWidth: 936,
          }}
        >
          Alabama Education Retirees Association
          District Map
        </H2>
        <BODImg
          src={bod}
          alt="board of director"
        />
      </Wrapper>
      <Wrapper
        type="m"
        display="flex"
        style={{
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        {data.map((d, index) => {
          return (
            <InfoCard
              key={index}
              heading={d.heading}
              title={d.title}
              phone={d.phone}
              mail={d.mail}
            />
          );
        })}
      </Wrapper>
      <LightDivider marginTop="60px" />
      <Wrapper
        type="m"
        marginTop="50px"
      ></Wrapper>
      <EmailSubscribe />
      <Wrapper
        type="m"
        marginTop="50px"
      ></Wrapper>
      <MarkImg src={mark} alt="AERA" />
    </BoardOfDirectorContainer>
  );
};

export default BoardOfDirector;
