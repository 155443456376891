import React from "react";
import Header, {
  getHeaderHeight,
} from "./components/Header/Header";
import {
  BrowserRouter,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
import HomePage from "./pages/HomePage/HomePage";

import Hero from "./components/Hero/Hero";
import Footer from "./components/Footer/Footer";
import JoinAERAPage from "./pages/JoinAERAPage/JoinAERAPage";
import AdvocacyPage from "./pages/AdvocacyPage/AdvocacyPage";
import ScholarshipsPage from "./pages/ScholarshipsPage/ScholarshipsPage";
import ContactPage from "./pages/ContactPage/ContactPage";
import HelpfulPage from "./pages/HelpfulPage/HelpfulPage";
import ClickAndSavePage from "./pages/ClickAndSavePage/ClickAndSavePage";
import AboutUsPage from "./pages/AboutUsPage/AboutUsPage";
import NewsPage from "./pages/NewsPage/NewsPage";
import DocumentPage from "./pages/DocumentPage/DocumentPage";
import { Jobs } from "./components/Jobs/Jobs";
export const HeaderScroll = () => {
  if (
    document.documentElement.scrollTop < getHeaderHeight()
  )
    document.documentElement.scrollTop = 0;
  else
    document.documentElement.scrollTop = getHeaderHeight();
};
const HandleScroll = () => {
  const history = useHistory();
  history.listen(() => {
    HeaderScroll();
  });
  return null;
};
function App() {
  return (
    <BrowserRouter>
      <>
        <HandleScroll />
        <Header />
        <Hero />
        <Switch>
          <Route path="/" exact>
            <HomePage />
          </Route>
          <Route path="/join" exact>
            <JoinAERAPage />
          </Route>
          <Route path="/advocacy" exact>
            <AdvocacyPage />
          </Route>
          <Route path="/scholarships" exact>
            <ScholarshipsPage />
          </Route>
          <Route path="/contact" exact>
            <ContactPage />
          </Route>
          <Route path="/helpful" exact>
            <HelpfulPage />
          </Route>
          <Route path="/click_and_save" exact>
            <ClickAndSavePage />
          </Route>
          <Route path="/about">
            <AboutUsPage />
          </Route>
          <Route path="/news">
            <NewsPage />
          </Route>
          <Route path="/documents">
            <DocumentPage />
          </Route>
          <Route path="/job-openings/:id" exact>
            <Jobs />
          </Route>
        </Switch>
        <Footer />
      </>
    </BrowserRouter>
  );
}

export default App;
