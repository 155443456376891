import styled from "styled-components";
import DS from "../DS";

const TextArea = styled.textarea`
  border: 1px solid #707070;
  height: 49px;
  padding: 20px;
  font-family: ${DS.fontFamily.NeueHaasUnica};
  font-size: 16px;
  outline: none;
  resize: none;
  min-height: 100px;
  textarea::placeholder{
    color: #989898 !important;
}
`;

export default TextArea;
