import styled, { css } from "styled-components";
import BreakPointsFontsMedia from "../../utils/BreakPointsFontsMedia";
import DS from "../DS";
import {
  BaseTypoCSS,
  BaseTypoProps,
} from "./BaseTypo";

export const Body1CSS = (
  props: BaseTypoProps = {}
) => css<BaseTypoProps>`
  ${BaseTypoCSS({
    fontFamily: "NeueHaasUnica",
    ...props,
  })};
  font-style: normal;
  font-size: 15px;
  line-height: 27px;
  letter-spacing: 0;
  font-weight: normal;
  ${BreakPointsFontsMedia(
    DS.breakpoints,
    18,
    15,
    12
  )};
`;

const Body1 = styled.p<BaseTypoProps>`
  ${Body1CSS()}
`;

export default Body1;
