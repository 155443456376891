import React, {
  FC,
  useEffect,
  useState,
} from "react";
import { Parallax } from "react-parallax";
import Wrapper from "../../DS/Wrapper/Wrapper";
import Map from "../../assets/images/dotted_map.png";
import styled from "styled-components";
import H1 from "../../DS/Typo/H1";
// import { Link as LLink } from "react-router-dom";
import SubTitle2 from "../../DS/Typo/SubTitle2";
import H4 from "../../DS/Typo/H4";
import Caption from "../../DS/Typo/Caption";
import { useScreenWidth } from "@tinyd/usescreensize";
import { useScreenClass } from "@tinyd/usescreenclass";
import { Fade } from "react-awesome-reveal";
import getEvents, {
  Event,
} from "../../services/getEvents";
const Link = styled.div``;
const Divider = styled.div`
  height: 1px;
  background-color: #c9c9c9;
`;
const UpComingContainer = styled.div`
  display: flex;
  flex: 1;
`;
const UpComingColumns = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const UpComingEvents: FC<{}> = () => {
  const [events, setEvents] = useState<Event[]>(
    []
  );
  const screen = useScreenClass();
  const isSmall = ["sm", "xs"].includes(screen);
  const width = useScreenWidth();
  const flexDirection =
    width < 880 ? "column" : "row";
  const marginTop = width < 880 ? 50 : 0;
  const marginLeft = width < 880 ? 0 : 20;
  const direction = width < 880 ? "up" : "right";
  const fit = isSmall
    ? "contain"
    : width < 880
    ? "contain"
    : "scale-down";
  useEffect(() => {
    getEvents()
      .then((events) => {
        console.log(events);
        setEvents(events);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  if (!events.length) {
    return null;
  }

  const first = events[0];
  const rest = events.slice(1);
  console.log(rest, events.slice(1), events);
  return (
    <Parallax
      style={{
        marginTop: 100,
      }}
      bgImage={Map}
      strength={-200}
      bgImageSizes="500px"
      bgImageStyle={{
        objectFit: fit,
        objectPosition: "center",
        maxWidth: "200vw",
      }}
    >
      <Wrapper
        type="m"
        style={{
          minHeight: 700,
          objectFit: "scale-down",
        }}
        display="flex"
        flexDirection="column"
        marginBottom="100px"
      >
        <Divider />
        <UpComingContainer
          style={{
            flexDirection,
          }}
        >
          <UpComingColumns
            style={{
              marginTop,
            }}
          >
            <H1>Upcoming Events</H1>

            <SubTitle2
              fontColor="magenta"
              marginTop="47px"
              marginBottom="18px"
            >
              {first.acf.date}
            </SubTitle2>
            <Link>
              {" "}
              <H4
                style={{
                  fontWeight: "bold",
                  maxWidth: 480,
                }}
              >
                {first.title.rendered}
              </H4>
            </Link>
          </UpComingColumns>
          <UpComingColumns
            style={{
              marginLeft,
            }}
          >
            <Fade
              style={{
                marginTop: 47,
              }}
              cascade
              direction={direction}
              damping={0.3}
              duration={300}
              triggerOnce
            >
              {rest.map((event, index) => {
                return (
                  <Link
                    // to={event.link}
                    key={index}
                  >
                    <Caption
                      fontColor="magenta"
                      marginBottom="18px"
                    >
                      {event.acf.date}
                    </Caption>
                    <SubTitle2
                      style={{
                        fontWeight: "bold",
                        maxWidth: 480,
                      }}
                    >
                      {event.title.rendered}
                    </SubTitle2>
                    {rest.length !== index + 1 ? (
                      <Divider
                        style={{
                          marginTop: 50,
                        }}
                      />
                    ) : null}
                  </Link>
                );
              })}
            </Fade>
          </UpComingColumns>
        </UpComingContainer>
      </Wrapper>
    </Parallax>
  );
};

export default UpComingEvents;
