import styled from "styled-components";
import BreakPointsFontsMedia from "../../utils/BreakPointsFontsMedia";
import DS from "../DS";
import {
  BaseTypoCSS,
  BaseTypoProps,
} from "./BaseTypo";

const SubTitle2 = styled.p<BaseTypoProps>`
  ${BaseTypoCSS({
    fontFamily: "NeueHaasUnica",
  })}
  font-style: normal;
  font-size: 15px;
  line-height: 19px;
  letter-spacing: 0;
  font-weight: 500;
  ${BreakPointsFontsMedia(
    DS.breakpoints,
    20,
    15,
    10
  )};
`;

export default SubTitle2;
