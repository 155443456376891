import styled from "styled-components";
import BreakPointsFontsMedia from "../../utils/BreakPointsFontsMedia";
import DS from "../DS";
import {
  BaseTypoCSS,
  BaseTypoProps,
} from "./BaseTypo";

const Caption = styled.p<BaseTypoProps>`
  ${BaseTypoCSS({
    fontFamily: "NeueHaasUnica",
  })}
  font-style: normal;
  font-size: 14px;
  line-height: 27px;
  letter-spacing: 0;
  font-weight: 500;
  ${BreakPointsFontsMedia(
    DS.breakpoints,
    16,
    14,
    3
  )};
`;

export default Caption;
