import axios from "axios";
import dayjs from "dayjs";
import { ENDPOINT } from "../utils/api";

const subscribeEmail = (
  EMAIL: string
): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    let form = new FormData();
    form.append(
      "_mc4wp_form_element_id",
      "mc4wp-form-1"
    );
    form.append("EMAIL", EMAIL);
    form.append("_mc4wp_honeypot", "");
    form.append(
      "_mc4wp_timestamp",
      dayjs().unix().toString()
    );
    form.append("_mc4wp_form_id", "37");
    axios
      .post(`${ENDPOINT}/mc4wp/v1/form`, form)
      .then((res) => {
        console.log(res);
        resolve(true);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};

export default subscribeEmail;
