const ScaleRangeNumber = (
  num: number,
  in_min: number,
  in_max: number,
  out_min: number,
  out_max: number
) => {
  return (
    ((Math.min(Math.max(num, in_min), in_max) -
      in_min) *
      (out_max - out_min)) /
      (in_max - in_min) +
    out_min
  );
};

export default ScaleRangeNumber;
