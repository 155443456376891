import React, {
  FC,
  useEffect,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import DS from "../../DS/DS";

const TriColorDividerContainer = styled.div`
  display: flex;
  height: 8px;
  & > div {
    flex: 1;
    height: 8px;
  }
  .td-red {
    background-color: ${DS.colors.magenta};
  }
  .td-blue {
    background-color: ${DS.colors.blue};
  }
  .td-yellow {
    background-color: ${DS.colors.yellow};
  }
`;

const TriColorDividerAnimatedContainer = styled.div`
  display: flex;
  height: 8px;
  background-color: ${DS.colors.lightGray};
  justify-content: center;
  width: 100vw;
  overflow: hidden;
  & > div {
    width: 0px;
    height: 8px;
    transition: all 1s ease;
    flex-shrink: 0;
  }
  .td-red {
    background-color: ${DS.colors.magenta};
  }
  .td-blue {
    background-color: ${DS.colors.blue};
  }
  .td-yellow {
    background-color: ${DS.colors.yellow};
  }
  .td-gray {
    background-color: ${DS.colors.lightGray};
  }
`;

const TriColorDividerAnimated: FC = () => {
  const timerId = useRef<number>(-1);
  const [step, setStep] = useState(0);

  useEffect(() => {
    clearInterval(timerId.current);
    const id = setInterval(() => {
      setStep((prevStep) => {
        if (prevStep > 4) {
          return 0;
        }
        return prevStep + 1;
      });
      return () => {};
    }, 700);
    timerId.current = id as any as number;
    return () => clearInterval(id);
  }, [setStep]);
  return (
    <TriColorDividerAnimatedContainer>
      <div
        className="td-blue"
        style={{
          width: step > 0 ? "50vw" : "0px",
          display: step === 5 ? "none" : "block",
        }}
      ></div>
      <div
        className="td-red"
        style={{
          width: step > 1 ? "50vw" : "0px",
          display: step === 5 ? "none" : "block",
        }}
      ></div>
      <div
        className="td-yellow"
        style={{
          width: step > 2 ? "50vw" : "0px",
          display: step === 5 ? "none" : "block",
        }}
      ></div>
      <div
        className="td-gray"
        style={{
          width: step > 3 ? "50vw" : "0px",
          display: step === 5 ? "none" : "block",
        }}
      ></div>
      <div
        className="td-gray"
        style={{
          width: step > 3 ? "50vw" : "0px",
          display: step === 5 ? "none" : "block",
        }}
      ></div>
      <div
        className="td-yellow"
        style={{
          width: step > 2 ? "50vw" : "0px",
          display: step === 5 ? "none" : "block",
        }}
      ></div>
      <div
        className="td-red"
        style={{
          width: step > 1 ? "50vw" : "0px",
          display: step === 5 ? "none" : "block",
        }}
      ></div>
      <div
        className="td-blue"
        style={{
          width: step > 0 ? "50vw" : "0px",
          display: step === 5 ? "none" : "block",
        }}
      ></div>
    </TriColorDividerAnimatedContainer>
  );
};

const TriColorDivider: FC<{ loading?: boolean }> =
  ({ loading = false }) => {
    if (loading) {
      return <TriColorDividerAnimated />;
    }
    return (
      <TriColorDividerContainer>
        <div className="td-red"></div>
        <div className="td-blue"></div>
        <div className="td-yellow"></div>
      </TriColorDividerContainer>
    );
  };

export default TriColorDivider;
