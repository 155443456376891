import React from "react";
import { Parallax } from "react-parallax";
import styled from "styled-components";
import heroBG from "../../assets/images/home_hero.jpg";
import DS from "../../DS/DS";
import Color from "color";
import {
  Slide,
  Fade,
} from "react-awesome-reveal";
import H1 from "../../DS/Typo/H1";
import { getHeaderHeight } from "../Header/Header";
import Button from "../../DS/Button/Button";
import { Link } from "react-router-dom";
const HeroContainer = styled.div`
  height: calc(100vh - ${getHeaderHeight()}px);
  background: linear-gradient(
    0deg,
    ${Color(DS.colors.darkPurple)
        .rgb()
        .alpha(0.99)
        .toString()}
      50%,
    ${Color(DS.colors.darkPurple)
        .rgb()
        .alpha(0.5)
        .toString()}
      100%
  );
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Hero404 = () => {
  return (
    <Parallax bgImage={heroBG} blur={5}>
      <HeroContainer>
        <Slide direction="up" cascade>
          <Fade
            style={{
              display: "flex",
              justifyContent: "center",
            }}
            cascade
          >
            <H1 fontColor="white">
              404 - Page Not Found
            </H1>
            <Link to="/">
              <Button marginTop="20px">
                Home
              </Button>
            </Link>
          </Fade>
        </Slide>
      </HeroContainer>
    </Parallax>
  );
};

export default Hero404;
