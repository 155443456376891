import React from "react";
import { Parallax } from "react-parallax";
import styled from "styled-components";
import heroBG from "../../assets/images/home_hero.jpg";
import DS from "../../DS/DS";
import Color from "color";
import { Slide, Fade } from "react-awesome-reveal";
import H1 from "../../DS/Typo/H1";
import Wrapper from "../../DS/Wrapper/Wrapper";
import useSharedState from "../../hooks/useSharedState/useSharedState";
import Body1 from "../../DS/Typo/Body1";

const HeroJobsContainer = styled.div`
  height: 492px;
  background: linear-gradient(
    0deg,
    ${Color(DS.colors.darkPurple)
        .rgb()
        .alpha(0.99)
        .toString()}
      10%,
    ${Color(DS.colors.darkPurple).rgb().alpha(0).toString()}
      100%
  );
  display: flex;
  align-items: center;
`;
const HeroJobs = () => {
  const [title] = useSharedState(
    "news_title",
    "Job Openings"
  );
  const [bg] = useSharedState("news_banner", heroBG);
  const [date] = useSharedState("news_date", "");
  return (
    <Parallax
      bgImage={bg}
      strength={-100}
      bgImageStyle={{
        height: 600,
        objectFit: "cover",
      }}
    >
      <HeroJobsContainer>
        <Wrapper type="m">
          <Slide
            direction="up"
            triggerOnce
            cascade
            duration={500}
          >
            {date.length ? (
              <Fade triggerOnce>
                <Body1
                  fontColor="white"
                  style={{
                    textAlign: "center",
                  }}
                >
                  {date}
                </Body1>
              </Fade>
            ) : (
              <></>
            )}
            <Fade triggerOnce>
              <H1
                fontColor="white"
                style={{
                  textAlign: "center",
                }}
              >
                {title}
              </H1>
            </Fade>
          </Slide>
        </Wrapper>
      </HeroJobsContainer>
    </Parallax>
  );
};

export default HeroJobs;
