import React from "react";
import styled from "styled-components";
import H2 from "../../DS/Typo/H2";
import H3 from "../../DS/Typo/H3";
import Wrapper from "../../DS/Wrapper/Wrapper";
import LinkCard from "../LinkCard/LinkCard";
import TriColorDivider from "../TriColorDivider/TriColorDivider";
import ama from "../../assets/icons/helpful/amba_l.png";
import aea from "../../assets/icons/helpful/aea.png";
import neab from "../../assets/icons/helpful/neab.png";
import LightDivider from "../LightDivider/LightDivider";
import EmailSubscribe from "../EmailSubscribe/EmailSubscribe";

const ClickAndSaveContainer = styled.div``;

const clickAndSaveData = [
  {
    text: "AERA AMBA Benefits",
    icon: ama,
    link: "https://amba-review.com/AERA",
  },

  {
    text: "AEA Access Benefits",
    icon: aea,
    link: "//accessdevelopment.com",
  },
  {
    text: "NEA Member Benefits",
    icon: neab,
    link: "//www.neamb.com/",
  },
];

const ClickAndSave = () => {
  return (
    <ClickAndSaveContainer>
      <TriColorDivider />
      <Wrapper type="m" marginTop="50px">
        <H3
          fontColor="magenta"
          style={{
            textAlign: "center",
          }}
        >
          Click and Save with Exclusive Member
          Benefits
        </H3>
        <H2
          style={{
            textAlign: "center",
          }}
        >
          Your membership gives you exclusive
          access to AERA, AEA, and NEA member
          benefits & discounts!
        </H2>
      </Wrapper>
      <Wrapper
        type="m"
        marginTop="76px"
        display="flex"
        justifyContent="space-between"
        style={{
          flexWrap: "wrap",
        }}
        marginBottom="100px"
      >
        {clickAndSaveData.map((link, index) => {
          return (
            <LinkCard
              key={index}
              text={link.text}
              icon={link.icon}
              link={link.link}
            />
          );
        })}
      </Wrapper>
      <LightDivider />
      <EmailSubscribe />
      <Wrapper type="m" marginTop="80px" />
    </ClickAndSaveContainer>
  );
};

export default ClickAndSave;
