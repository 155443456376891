import Color from "color";
import React, { FC } from "react";
import { Fade } from "react-awesome-reveal";
import { Parallax } from "react-parallax";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Button from "../../DS/Button/Button";
import Body1 from "../../DS/Typo/Body1";
import H2 from "../../DS/Typo/H2";
import H4 from "../../DS/Typo/H4";
import Wrapper from "../../DS/Wrapper/Wrapper";
import es from "../../assets/icons/es.svg";
import mb from "../../assets/icons/mb.svg";
import lb from "../../assets/icons/lb.svg";
import ib from "../../assets/icons/ib.svg";
import DS from "../../DS/DS";
import { useScreenClass } from "@tinyd/usescreenclass";
import { useScreenWidth } from "@tinyd/usescreensize";

const JoinCTAContainer = styled.div`
  background: linear-gradient(
    ${Color("#212121")
        .rgb()
        .alpha(0.8)
        .toString()}
      0%,
    ${Color("#2b2b54")
        .rgb()
        .alpha(0.7)
        .toString()}
      100%
  );
`;

const BenefitsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  & > div {
    background-color: ${DS.colors.white};
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  }
`;

const BenefitsIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 140px;
  img {
    margin-bottom: 40px;
  }
`;

const Benefits: FC<{
  text: string;
  icon: string;
}> = ({ text, icon }) => {
  const screen = useScreenClass();
  const isSmall = ["sm", "xs"].includes(screen);
  const marginBottom = isSmall ? 20 : 40;
  const width = isSmall ? 26 : "auto";
  const maxWidth = isSmall ? 120 : 150;
  const justifyContent = isSmall
    ? "center"
    : "space-between";
  return (
    <BenefitsIconContainer
      style={{
        justifyContent,
        maxWidth,
      }}
    >
      <img
        src={icon}
        alt={text}
        style={{ marginBottom, width }}
      />
      <H4
        style={{
          textAlign: "center",
        }}
      >
        {text}
      </H4>
    </BenefitsIconContainer>
  );
};

const JoinCTA: FC<{ bgUrl: string }> = ({
  bgUrl,
}) => {
  const size = useScreenWidth();

  const screen = useScreenClass();
  const isSmall = ["sm", "xs"].includes(screen);
  const width = isSmall
    ? 146
    : size < 1140
    ? size < 870
      ? size / 2 - 60
      : 190
    : 246;
  const marginBottom = isSmall ? 14 : 30;
  const marginTop = isSmall ? 38 : 75;
  return (
    <div
      style={{
        marginTop: "90px",
      }}
    >
      <Parallax
        bgImage={bgUrl}
        blur={{ min: 50, max: -70 }}
        strength={-100}
      >
        <JoinCTAContainer>
          <Wrapper
            type="m"
            paddingTop="100px"
            paddingBottom="70px"
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <H2
              fontColor="white"
              style={{
                textAlign: "center",
              }}
            >
              Join Us today!
            </H2>
            <H2
              fontColor="white"
              style={{
                textAlign: "center",
              }}
            >
              Our Strength is in Our Members
            </H2>
            <Body1
              fontColor="white"
              marginBottom="33px"
              style={{
                textAlign: "center",
                maxWidth: 815,
              }}
            >
              AERA has partnered with Association
              Member Benefits Advisors (AMBA) so
              that you have access to exceptional
              member benefits. Are you wondering
              if certain benefits are right for
              you?
            </Body1>
            <Fade direction="up" triggerOnce>
              <Link to="/join">
                <Button
                  hoverColor="magenta"
                  //paddingTop="4px"
                >
                  Join Now
                </Button>
              </Link>
            </Fade>

            <BenefitsContainer
              style={{ marginTop }}
            >
              <Fade
                duration={400}
                style={{
                  width,
                  height: width,
                  marginBottom,
                }}
                direction="up"
                cascade
                triggerOnce
              >
                <Benefits
                  text="Everyday Savings"
                  icon={es}
                />
                <Benefits
                  text="Medical Benefits"
                  icon={mb}
                />
                <Benefits
                  text="Legal Benefits"
                  icon={lb}
                />
                <Benefits
                  text="Insurance Benefits"
                  icon={ib}
                />
              </Fade>
            </BenefitsContainer>
          </Wrapper>
        </JoinCTAContainer>
      </Parallax>
    </div>
  );
};

export default JoinCTA;
