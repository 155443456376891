import React from "react";
import { Parallax } from "react-parallax";
import styled from "styled-components";
import heroBG from "../../assets/images/join_bg.jpg";
import DS from "../../DS/DS";
import Color from "color";
import {
  Slide,
  Fade,
} from "react-awesome-reveal";
import H1 from "../../DS/Typo/H1";
import Wrapper from "../../DS/Wrapper/Wrapper";
import Body1 from "../../DS/Typo/Body1";
import { getHeaderHeight } from "../Header/Header";
import { Link } from "react-router-dom";
import { useScreenWidth } from "@tinyd/usescreensize";
const HeroJoinAERAContainer = styled.div`
  height: calc(100vh - ${getHeaderHeight()}px);
  min-height: 700px;
  background: linear-gradient(
    0deg,
    ${Color(DS.colors.darkPurple)
        .rgb()
        .alpha(0.99)
        .toString()}
      10%,
    ${Color(DS.colors.darkPurple)
        .rgb()
        .alpha(0.2)
        .toString()}
      100%
  );
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const LinkHover = styled(Link)`
  margin-bottom: 20px;
  & > p,
  & > h3 {
    text-align: center;
    transition: all 0.3s ease;
    &:hover {
      color: ${DS.colors.yellow};
    }
  }
`;

const HeroJoinAERA = () => {
  const screenWidth = useScreenWidth();
  const isSmall = screenWidth < 880;
  return (
    <Parallax
      bgImage={heroBG}
      bgImageStyle={{
        objectPosition: "bottom center",
      }}
      strength={-100}
    >
      <HeroJoinAERAContainer>
        <Wrapper
          type="m"
          flex="1"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Slide
            direction="up"
            triggerOnce
            cascade
            duration={500}
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Fade triggerOnce>
              <H1
                fontColor="white"
                style={{ textAlign: "center" }}
              >
                Join AERA
              </H1>
            </Fade>
            <Fade delay={500} triggerOnce>
              <Body1
                fontColor="white"
                style={{
                  maxWidth: 746,
                  textAlign: "center",
                  marginTop: "30px",
                }}
              >
                Membership in the Alabama
                Education Retirees Association,
                Inc. (AERA) is open to anyone who
                receives retirement benefits from
                the Teachers’ Retirement System of
                Alabama. AERA has over 21,000
                members working diligently to
                protect the hard-earned benefits
                and interests you were promised.
              </Body1>
            </Fade>
          </Slide>
        </Wrapper>
        <Wrapper
          type="m"
          flexDirection={
            isSmall ? "column" : "row"
          }
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginBottom="75px"
        >
          <LinkHover
            to={{
              pathname:
                "//myaea.org/digital-membership-forms-5",
            }}
            target="_blank"
          >
            <Body1
              fontColor="white"
              style={{
                fontWeight: 600,
                fontStyle: "italic",
              }}
            >
              JOIN AERA/AEA Today!
            </Body1>
          </LinkHover>
          {/* <LinkHover
            to={{
              pathname:
                "https://myaea.org/digital-membership-forms-5",
            }}
            target="_blank"
          >
            <Body1
              fontColor="white"
              style={{
                fontWeight: 600,
                fontStyle: "italic",
              }}
            >
              Membership Form
            </Body1>
          </LinkHover> */}
          <LinkHover
            to={{
              pathname:
                "https://www.aerainc.org/wp-content/uploads/2024/03/The-AERA-Voice-Magazine-Cover.pdf",
            }}
            target="_blank"
          >
            <Body1
              fontColor="white"
              style={{
                fontWeight: 600,
                fontStyle: "italic",
              }}
            >
              Associate Member Form
            </Body1>
          </LinkHover>
        </Wrapper>
      </HeroJoinAERAContainer>
    </Parallax>
  );
};

export default HeroJoinAERA;
