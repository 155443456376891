import Color from "color";
import React, {
  FC,
  useLayoutEffect,
  useRef,
} from "react";

import { Parallax } from "react-parallax";

import styled from "styled-components";

import H2 from "../../DS/Typo/H2";
import H4 from "../../DS/Typo/H4";
import Wrapper from "../../DS/Wrapper/Wrapper";

import DS from "../../DS/DS";
import H3 from "../../DS/Typo/H3";
import Body1 from "../../DS/Typo/Body1";
import { useLocation } from "react-router";

const WeStandForContainer = styled.div`
  background: linear-gradient(
    ${Color("#212121")
        .rgb()
        .alpha(0.8)
        .toString()}
      0%,
    ${Color("#2b2b54")
        .rgb()
        .alpha(0.97)
        .toString()}
      100%
  );
`;

const weStandData = [
  "AERA is the voice for all education retirees and the voice is strengthened by the recruitment of new members.",
  "AERA supports all measures to assure earned benefits.",
  "AERA supports health benefits for all public education retirees.",
  "AERA opposes actions that diminish benefits for retirees.",
  "AERA supports the election of TRS Board of Control members by all active and retired participants.",
  "AERA promotes individuals who support and vote for public education retirees’ interests. ",
];

const MiniDivider = styled.div`
  height: 3px;
  width: 80px;
  background-color: ${DS.colors.magenta};
  margin-top: 40px;
  margin-bottom: 40px;
`;

const WeStandFor: FC<{ bgUrl: string }> = ({
  bgUrl,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const location = useLocation();
  useLayoutEffect(() => {
    if (location.hash === "#what_we_stand_for") {
      console.log("true");
      setTimeout(() => {
        ref.current?.scrollIntoView({
          behavior: "smooth",
        });
      }, 500);
    }
  }, [location, ref]);
  return (
    <div
      ref={ref}
      style={{
        marginTop: "90px",
      }}
    >
      <Parallax
        bgImage={bgUrl}
        blur={{ min: 50, max: -70 }}
        strength={-100}
      >
        <WeStandForContainer>
          <Wrapper
            type="m"
            paddingTop="100px"
            paddingBottom="100px"
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <H2
              fontColor="white"
              style={{
                textAlign: "center",
              }}
            >
              What We Stand For
            </H2>
            <H3
              fontColor="white"
              style={{
                textAlign: "center",
                fontStyle: "italic",
              }}
              fontFamily="NeueHaasUnica"
            >
              Alabama Education Retirees
              Association, Inc. (AERA) is
              dedicated to actively protect the
              benefits and interests for all
              Alabama public education retirees.
            </H3>
            <MiniDivider />
            <H4
              fontColor="white"
             // marginBottom="33px"
              style={{
                textAlign: "center",
              }}
            >
              Policy Issues
            </H4>
            <ul
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {weStandData.map((text, index) => {
                return (
                  <Body1
                    fontColor="white"
                    key={index}
                    as="li"
                  >
                    {text}
                  </Body1>
                );
              })}
            </ul>
          </Wrapper>
        </WeStandForContainer>
      </Parallax>
    </div>
  );
};

export default WeStandFor;
