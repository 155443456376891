import Color from "color";
import React, { FC } from "react";
import { Fade } from "react-awesome-reveal";
import { Parallax } from "react-parallax";

import styled from "styled-components";

import H2 from "../../DS/Typo/H2";

import Wrapper from "../../DS/Wrapper/Wrapper";
import DS from "../../DS/DS";
import { useScreenClass } from "@tinyd/usescreenclass";
import { useScreenWidth } from "@tinyd/usescreensize";
import H3 from "../../DS/Typo/H3";
import dental from "../../assets/icons/whoweare/dental.svg";
import guard from "../../assets/icons/whoweare/guard.svg";
import home from "../../assets/icons/whoweare/home.svg";
import medical from "../../assets/icons/whoweare/medical.svg";
import long from "../../assets/icons/whoweare/long.svg";
import annuity from "../../assets/icons/whoweare/annuity.svg";
import medicare from "../../assets/icons/whoweare/medicare.svg";
import american from "../../assets/icons/whoweare/american.svg";
import cancer from "../../assets/icons/whoweare/cancer.svg";
import Body1 from "../../DS/Typo/Body1";

const MembersBenefitsContainer = styled.div`
  background: linear-gradient(
    ${Color("#212121")
        .rgb()
        .alpha(0.8)
        .toString()}
      0%,
    ${Color("#2b2b54")
        .rgb()
        .alpha(0.7)
        .toString()}
      100%
  );
`;

const BenefitsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  & > div {
    background-color: ${DS.colors.white};
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  }
`;

const BenefitsIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 140px;

  img {
    margin-bottom: 40px;
  }
`;

const Benefits: FC<{
  text: string;
  icon: string;
}> = ({ text, icon }) => {
  const screen = useScreenClass();
  const isSmall = ["sm", "xs"].includes(screen);
  const marginBottom = isSmall ? 20 : 30;
  const width = isSmall ? 26 : "auto";
  const maxWidth = isSmall ? 120 : 150;
  const justifyContent = isSmall
    ? "center"
    : "space-between";
  return (
    <BenefitsIconContainer
      style={{
        justifyContent,
        maxWidth,
      }}
    >
      <img
        src={icon}
        alt={text}
        style={{ marginBottom, width }}
      />
      <Body1
        style={{
          textAlign: "center",
          fontWeight: 500,
        }}
      >
        {text}
      </Body1>
    </BenefitsIconContainer>
  );
};

const MembersBenefits: FC<{ bgUrl: string }> = ({
  bgUrl,
}) => {
  const size = useScreenWidth();

  const screen = useScreenClass();
  const isSmall = ["sm", "xs"].includes(screen);
  const width = isSmall
    ? 146
    : size < 1140
    ? size < 870
      ? size / 2 - 60
      : 190
    : 201;
  const marginBottom = isSmall ? 14 : 30;
  const marginTop = isSmall ? 38 : 75;
  return (
    <div
      style={{
        marginTop: "90px",
      }}
    >
      <Parallax bgImage={bgUrl} strength={-100}>
        <MembersBenefitsContainer>
          <Wrapper
            type="m"
            paddingTop="100px"
            paddingBottom="100px"
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <H3
              fontColor="white"
              style={{
                textAlign: "center",
              }}
            >
              Why Join Us
            </H3>
            <H2
              fontColor="white"
              style={{
                textAlign: "center",
              }}
            >
              Members Benefits
            </H2>

            <BenefitsContainer
              style={{
                marginTop,
                ...(isSmall
                  ? {
                      justifyContent:
                        "space-between",
                    }
                  : {}),
              }}
            >
              <Fade
                duration={400}
                style={{
                  width,
                  height: width,
                  marginBottom,
                  marginRight: isSmall ? 0 : 30,
                }}
                direction="up"
                cascade
                triggerOnce
              >
                <Benefits
                  text="Dental & Vision Plans"
                  icon={dental}
                />
                <Benefits
                  text="Guaranteed Life Insurance"
                  icon={guard}
                />
                <Benefits
                  text="Home Health Care Insurance"
                  icon={home}
                />
                <Benefits
                  text="Medical Air Services"
                  icon={medical}
                />
                <Benefits
                  text="Long Term Care Insurance"
                  icon={long}
                />
                <Benefits
                  text="Annuity"
                  icon={annuity}
                />
                <Benefits
                  text="Medicare Supplement Plans"
                  icon={medicare}
                />
                <Benefits
                  text="American Hearing Benefits"
                  icon={american}
                />
                <Benefits
                  text="Cancer, Heart & Stroke Insurance"
                  icon={cancer}
                />
              </Fade>
            </BenefitsContainer>
          </Wrapper>
        </MembersBenefitsContainer>
      </Parallax>
    </div>
  );
};

export default MembersBenefits;
