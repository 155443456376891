import React, {
  FC,
  useEffect,
  useState,
} from "react";
import { Link } from "react-router-dom";
import Body1 from "../../DS/Typo/Body1";
import H2 from "../../DS/Typo/H2";
import Wrapper from "../../DS/Wrapper/Wrapper";
import arrow from "../../assets/icons/arrow.svg";
import ScrollContainer from "react-indiana-drag-scroll";
import styled from "styled-components";
import NewsCard from "../NewsCard/NewsCard";
import { Fade } from "react-awesome-reveal";
import { useScreenClass } from "@tinyd/usescreenclass";
import getHomeNews, {
  NewsType,
} from "../../services/getHomeNews";
import DS from "../../DS/DS";

const NewsScrollContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  & > *:last-child {
    margin-right: 50px;
  }
`;

const LinkStyled = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
  & img {
    transition: all 0.3s ease;
  }
  &:hover {
    transform: scale(1.05);
    & p {
      color: ${DS.colors.darkPurple};
    }
    & img {
      filter: hue-rotate(-110deg) brightness(0.5);
      transform: translateX(10px);
    }
  }
  img {
    margin-left: 10px;
    top: 2px;
    position: relative;
  }
`;

const ViewAllNews = () => {
  return (
    <LinkStyled to="/news">
      <Body1
        fontColor="magenta"
        style={{
          fontWeight: "bold",
        }}
      >
        View All News{" "}
        <img src={arrow} alt="news" />
      </Body1>
    </LinkStyled>
  );
};

const NewsRoomCTA: FC = () => {
  const [newsItems, setNews] = useState<
    NewsType[]
  >([]);
  const screen = useScreenClass();
  const isSmall = ["sm", "xs"].includes(screen);
  const marginTop = isSmall ? 23 : 50;
  useEffect(() => {
    getHomeNews()
      .then((news) => {
        setNews(news);
      })
      .catch((error) => console.log(error));
  }, []);
  if (!newsItems.length) return null;
  return (
    <div>
      <Wrapper
        type="m"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginTop="100px"
      >
        <H2>Newsroom</H2>
        {!isSmall ? <ViewAllNews /> : null}
      </Wrapper>
      <ScrollContainer
        style={{
          marginTop,
          paddingLeft: 50,
          paddingRight: 50,
        }}
        vertical={false}
        horizontal={true}
      >
        <NewsScrollContainer>
          <Fade
            duration={isSmall ? 200 : 400}
            cascade
            damping={0.4}
            {...(!isSmall
              ? { direction: "up" }
              : {})}
            triggerOnce
          >
            {newsItems.map(
              ({ title, acf, slug }, index) => {
                return (
                  <NewsCard
                    title={title.rendered}
                    category={acf.category.name}
                    img={acf.news_banner.url}
                    link={`/news/${slug}`}
                    key={index}
                  />
                );
              }
            )}
          </Fade>
        </NewsScrollContainer>
      </ScrollContainer>
      {isSmall ? (
        <Wrapper
          type="m"
          marginTop="25px"
          display="flex"
        >
          <ViewAllNews />
        </Wrapper>
      ) : null}
    </div>
  );
};

export default NewsRoomCTA;
