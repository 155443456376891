import React, { useCallback, useState } from "react";
import styled from "styled-components";
import Wrapper from "../../DS/Wrapper/Wrapper";
import jill from "../../assets/images/jill.jpg";
import Body1 from "../../DS/Typo/Body1";
import LightDivider from "../LightDivider/LightDivider";
import DS from "../../DS/DS";
import Caption from "../../DS/Typo/Caption";
import H4 from "../../DS/Typo/H4";
import mail from "../../assets/icons/mail_dark_red.svg";
import { Link } from "react-router-dom";
import EmailSubscribe from "../EmailSubscribe/EmailSubscribe";
import downArrow from "../../assets/images/downBubble.svg";
import keron from "../../assets/images/keron.jpg";
import teresa from "../../assets/images/teresa.jpg";
import Joni_Brasher from "../../assets/images/Joni_Brasher.jpeg"
import veronica from "../../assets/images/veronica.jpg";
import Caption2 from "../../DS/Typo/Caption2";
import Color from "color";
import { useScreenWidth } from "@tinyd/usescreensize";
import mark from "../../assets/images/mark.png";
import logo from "../../assets/images/logo_square.png";
const MarkImg = styled.img`
  position: absolute;
  top: 100%;
  right: 0;
  width: 110px;
`;
const StaffContainer = styled.div``;

const InfoContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

const ImageContainer = styled.div`
  max-width: 340px;
  width: 100%;
  flex-shrink: 0;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.16);
  background-color: ${DS.colors.magenta};
  & > img {
    width: 100%;
  }
`;

const LinkContainer = styled.div`
  margin-top: 10px;
  & > a,
  & > div {
    display: flex;
    margin-bottom: 10px;
    img {
      margin-right: 12px;
      width: 14px;
      height: 16px;
      margin-top: 6px;
    }
  }
`;

const data = [
  {
    name: "Keron Forte",
    img: keron,
    title: "Assistant Executive Director",
    mail: "kforte@aerainc.org",
    info: [
      "As Assistant Executive Director, I assist in the tasks associated with running the state association on a daily basis, including: assisting members, performing public relations duties, attending board meetings, attending state meetings, serving as an advocate with the state legislature, and visiting local units as needed. My main responsibility is serving as editor of our magazine, The AERA Voice! I layout, design, and edit the publication, which is mailed to all members. I have been with AERA since 2001. I am honored to work with the Board of Directors, our members, and be a part of an awesome staff!",
    ],
  },
  {
    name: "Joni Brasher",
    img: Joni_Brasher,
    title: "Bookkeeper/Membership Coordinator",
    mail: "jbrasher@aerainc.org",
    info: [
      "As Membership Coordinator/Bookkeeper, I assist in the daily tasks associated with running the state association on a daily basis. This includes preparing and mailing letters for membership, maintaining accurate records of the receipts and disbursements of the Association, assisting with AERA meetings, and answering members’ questions pertaining to membership. I enjoy working with the Board of Directors, the staff, and the members at AERA!",
    ],
  },
  {
    name: "Job Opening",
    img: logo,
    title: "Administrative Assistant",
    mail: "jjackson@aerainc.org",
    info: [
      `Alabama Education Retirees Association`,
      `Administrative Assistant`,
      `The Alabama Education Retirees Association has an Immediate Opening for a full-time Administrative Assistant.`,
      `
      `,
      `The Candidate should possess the following skills, knowledge, and abilities:`,
      `•	Minimum of three years executive administrative support experience`,
      `•	Application knowledge and usage of Microsoft Office: Word, Excel, PowerPoint, and Outlook`,
      `•	Strong customer service, oral and written communication skills, and interpersonal professionalism`,
      `•	Strong ability to write, edit, and proofread documents and communications`,
      `•	Maintain accurate documents records and file keeping`,
      `•	Plan, organize, and prepare for meetings`,
      `•	Manage phone calls in pleasant and cooperative manner`,
      `•	Team player in small office environment`,
      `•	Draft, review, and send mass communications`,
      `•	Acquires knowledge of association programs `,
      `•	Assists with office projects`,
      `•	Organized, detail oriented, excellent time management`,
      `•	Maintains Executive Director's calendar, scheduling of appointments, and travel arrangements`,
      `•	Performs all administrative duties as directed by Executive Director`,
      `Job Details: Monday-Friday 8 to 4, In person work location`,
      `Benefits: Teachers Retirement System Retirement, PEEHIP Health Insurance, 
Personal Leave, Most State Holidays, Additional Retirement Plan, Paid Time 
Off`,
      `Salary based on experience: $35,000-$39,000`,
      `Send resumes to jjackson@aerainc.org.`
    ],
  },
];

const StaffCommentContainer = styled.div``;

const StaffCommentItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StaffCommentItem = styled.div<{
  isActive: boolean;
  totalSize: number;
}>`
  ${({ totalSize, isActive }) => `
  width: calc(100% / ${totalSize} - 10px);
  filter: drop-shadow(
   ${isActive
      ? "0px 5px 5px rgba(0, 0, 0, 0.2)"
      : "0px 0px 0px rgba(0, 0, 0, 0.2)"
    }
  );
  `}
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;

  .sci-arrow {
    width: 32px;
    z-index: -1;
    ${({ isActive }) => `
     transform: translateY(${isActive ? "0px" : "-32px"});
   `}
    transition: all 0.3s ease;
  }

  .sci-container {
    display: flex;
    flex: 1;
    ${({ isActive }) => `
    background-color: ${isActive ? DS.colors.darkPurple : DS.colors.lightGray};
    width: calc(100% - 30px);
    `}
    padding: 20px 15px;
  }

  .sci-mail {
    ${({ isActive }) => `
    filter:brightness(${isActive ? 10 : 1});
    `}
  }
  .sci-img-container {
    margin-right: 25px;
    img {
      width: 94px;
      height: 94px;
      object-fit: cover;
      border-radius: 100%;
      ${({ isActive }) => `
      box-shadow:0px 0px 0px 5px ${isActive
      ? "rgba(255,255,255,0.23)"
      : Color(DS.colors.dimGray1).rgb().alpha(0.23).toString()
    };
      `}
    }
  }
`;
const StaffCommentTextContainer = styled.div`
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.16);
  padding: 25px 33px 16px 33px;
  margin-top: 12px;
  background: #fff;
`;

const StaffComment = () => {
  const width = useScreenWidth();
  const isSmall = width < 880;
  const [activeIndex, setIndex] = useState(0);
  const onClick = useCallback(
    (index) => {
      setIndex(index);
    },
    [setIndex]
  );
  return (
    <StaffCommentContainer>
      <StaffCommentItemContainer
        style={{
          flexDirection: isSmall ? "column" : "row",
        }}
      >
        {data.map((item, index) => {
          return (
            <StaffCommentItem
              isActive={isSmall ? true : activeIndex === index}
              key={index}
              totalSize={isSmall ? 1 : data.length}
              onClick={() => onClick(index)}
            >
              <div className="sci-container">
                <div className="sci-img-container">
                  <img src={item.img} alt={item.name} />
                </div>
                <div>
                  <H4
                    fontColor={
                      (isSmall ? true : activeIndex === index)
                        ? "white"
                        : "darkPurple"
                    }
                  >
                    {item.name}
                  </H4>
                  <Caption
                    fontColor={
                      (isSmall ? true : activeIndex === index)
                        ? "white"
                        : "darkPurple"
                    }
                    style={{
                      fontWeight: 400,
                    }}
                  >
                    {item.title}
                  </Caption>
                  <LinkContainer
                    style={{
                      marginTop: 0,
                    }}
                  >
                    <Link
                      to={{
                        pathname: `mailto:${item.mail}`,
                      }}
                      target="_top"
                    >
                      <img className="sci-mail" src={mail} alt={"mail"} />
                      <Caption2
                        fontColor={
                          (isSmall ? true : activeIndex === index)
                            ? "white"
                            : "darkPurple"
                        }
                        style={{
                          overflowWrap: "anywhere",
                          fontWeight: 400,
                          marginTop: 6,
                        }}
                      >
                        {item.mail}
                      </Caption2>
                    </Link>
                  </LinkContainer>
                </div>
              </div>
              <img className="sci-arrow" src={downArrow} alt="down arrow" />
              {isSmall ? (
                <StaffCommentTextContainer style={{ marginBottom: 40 }}>
                  {item.info.map((text, index) => {
                    return (
                      <Body1 key={index} marginTop={10}>
                        {text}
                      </Body1>
                    );
                  })}
                </StaffCommentTextContainer>
              ) : null}
            </StaffCommentItem>
          );
        })}
      </StaffCommentItemContainer>
      {!isSmall ? (
        <StaffCommentTextContainer>
          {data[activeIndex].info.map((text, index) => {
            return (
              <Body1 key={index} marginTop={10}>
                {text}
              </Body1>
            );
          })}
        </StaffCommentTextContainer>
      ) : null}
    </StaffCommentContainer>
  );
};

const Staff = () => {
  const width = useScreenWidth();
  const isSmall = width < 880;
  return (
    <StaffContainer>
      <Wrapper type="m" marginTop="100px">
        <InfoContainer
          style={
            isSmall
              ? {
                flexDirection: "column",
                alignItems: "center",
              }
              : {}
          }
        >
          <ImageContainer
            style={
              isSmall
                ? {
                  marginBottom: 40,
                }
                : {}
            }
          >
            <img src={jill} alt="jill" />
            <Caption
              marginTop={15}
              style={{ textAlign: "center" }}
              fontColor="white"
            >
              Executive Director
            </Caption>
            <H4
              fontColor="white"
              fontFamily="NeueHaasUnica"
              style={{
                textAlign: "center",
                fontWeight: "bold",
              }}
              marginBottom={15}
            >
              Jill E. Jackson
            </H4>
          </ImageContainer>
          <div
            style={{
              marginLeft: isSmall ? 0 : 50,
              ...(isSmall
                ? {
                  display: "flex",
                  flexDirection: "column-reverse",
                }
                : {}),
            }}
          >
            <Body1>
              Jill Jackson has been serving as the Alabama Education Retirees
              Association Executive Director and staff registered lobbyist since
              2018. Mrs Jackson has an extensive background in business and
              public education. She is a former high school teacher at Wetumpka
              High School. While employed in education, she was a member of both
              the Elmore County Education Association and the Alabama Education
              Association. Mrs Jackson has a Bachelor of Science in Psychology
              from the Auburn University of Montgomery, State of Alabama Teacher
              Certification from Athens University and Jacksonville State
              University, and a Master of Law from Regent University. She is
              married to Bryan Jackson and they have three daughters.
            </Body1>
            <LightDivider
              customPaddingSpacing={0}
              marginTop={"30px"}
              marginBottom={"30px"}
            />
            <LinkContainer>
              <Link
                to={{
                  pathname: `mailto:jjackson@aerainc.org`,
                }}
                target="_top"
              >
                <img src={mail} alt={"mail"} />
                <Body1
                  style={{
                    overflowWrap: "anywhere",
                  }}
                >
                  jjackson@aerainc.org
                </Body1>
              </Link>
            </LinkContainer>
          </div>
        </InfoContainer>
      </Wrapper>
      <Wrapper type="m" marginTop="50px">
        <StaffComment />
      </Wrapper>
      <LightDivider marginTop="60px" />

      <Wrapper type="m" marginTop="50px"></Wrapper>
      <EmailSubscribe />
      <Wrapper type="m" marginTop="50px"></Wrapper>
      <MarkImg src={mark} alt="AERA" />
    </StaffContainer>
  );
};

export default Staff;
