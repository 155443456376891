import styled from "styled-components";
import BreakPointsFontsMedia from "../../utils/BreakPointsFontsMedia";
import DS from "../DS";
import {
  BaseTypoCSS,
  BaseTypoProps,
} from "./BaseTypo";

const H2 = styled.p<BaseTypoProps>`
  ${BaseTypoCSS({
    fontFamily: "NeueHaasUnica",
  })}
  font-style: normal;
  font-size: 27px;
  line-height: 59px;
  letter-spacing: 0;
  font-weight: 800;
  ${BreakPointsFontsMedia(
    DS.breakpoints,
    48,
    27,
    18
  )};
`;

export default H2;
