import React, { FC } from "react";
import { useScreenWidth } from "@tinyd/usescreensize";
import styled from "styled-components";
import DS from "../../DS/DS";

const TableContainer = styled.div`
  background-color: ${DS.colors.lightGray3};
  box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.16);
`;

const TableMobileContainer = styled.div`
  padding-top: 4px;
  margin-bottom: 30px;
  background-color: ${DS.colors.lightGray3};
  box-shadow: inset 0px 4px 0px
      ${DS.colors.magenta},
    0px 4px 13px rgba(0, 0, 0, 0.16);
`;

const HeaderCol = styled.div<{ spacing: string }>`
  ${({ spacing }) => `padding: 19px ${spacing};`}

  background-color: ${DS.colors.magenta};
  box-shadow: -1px 0px 0px ${DS.colors.lightGray2};
  overflow: hidden;
`;

const TableCol = styled.div<{ spacing: string }>`
  ${({ spacing }) => `padding: 19px ${spacing};`}
  box-shadow: -1px -1px 0px ${DS.colors
    .lightGray2};
  overflow: hidden;
`;

const TableRow = styled.div`
  display: flex;
`;

const TableDesktop: FC<{
  headers: JSX.Element[];
  cols: string[];
  data: JSX.Element[][];
  spacing: string;
}> = ({ cols, data, headers, spacing }) => {
  return (
    <TableContainer>
      <TableRow>
        {cols.map((size = "unset", index) => {
          return (
            <HeaderCol
              spacing={spacing}
              key={index}
              style={{
                width: size,
                ...(index === 0
                  ? {
                      boxShadow: `0px -1px 0px ${DS.colors.lightGray2}`,
                    }
                  : {}),
              }}
            >
              {headers[index]}
            </HeaderCol>
          );
        })}
      </TableRow>
      {data.map((col, index) => {
        return (
          <TableRow key={index}>
            {cols.map((size = "unset", index) => {
              return (
                <TableCol
                  spacing={spacing}
                  key={index}
                  style={{
                    width: size,
                    ...(index === 0
                      ? {
                          boxShadow: `0px -1px 0px ${DS.colors.lightGray2}`,
                        }
                      : {}),
                  }}
                >
                  {col[index]}
                </TableCol>
              );
            })}
          </TableRow>
        );
      })}
    </TableContainer>
  );
};
const TableMobile: FC<{
  headers: JSX.Element[];
  cols: [string, string];
  data: JSX.Element[][];
  spacing: string;
}> = ({ cols, data, headers, spacing }) => {
  return (
    <>
      {data.map((row, index) => {
        return (
          <TableMobileContainer key={index}>
            {headers.map((header, index) => {
              return (
                <TableRow key={index}>
                  <TableCol
                    spacing={spacing}
                    style={{
                      width: cols[0],
                    }}
                  >
                    {header}
                  </TableCol>
                  <TableCol
                    spacing={spacing}
                    style={{
                      width: cols[1],
                    }}
                  >
                    {row[index]}
                  </TableCol>
                </TableRow>
              );
            })}
          </TableMobileContainer>
        );
      })}
    </>
  );
};

const Table: FC<{
  headers: JSX.Element[];
  mobileHeaders?: JSX.Element[];
  cols: string[];
  mobileCols: [string, string];
  data: JSX.Element[][];
  responsiveBreakPoint?: number;
  spacing?: string;
  mobileSpacing?: string;
}> = ({
  headers,
  mobileHeaders,
  cols,
  data,
  responsiveBreakPoint = 600,
  mobileCols,
  spacing = "5%",
  mobileSpacing = "5%",
}) => {
  const screenWidth = useScreenWidth();
  const isSmall =
    screenWidth <= responsiveBreakPoint;

  if (isSmall) {
    return (
      <TableMobile
        cols={mobileCols}
        data={data}
        headers={mobileHeaders || headers}
        spacing={mobileSpacing}
      />
    );
  }

  return (
    <TableDesktop
      cols={cols}
      data={data}
      headers={headers}
      spacing={spacing}
    />
  );
};

export default Table;
