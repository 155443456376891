import { useScreenClass } from "@tinyd/usescreenclass";
import Color from "color";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import DS from "../../DS/DS";
import Body1 from "../../DS/Typo/Body1";
import H2 from "../../DS/Typo/H2";
import H3 from "../../DS/Typo/H3";
import SubTitle1 from "../../DS/Typo/SubTitle1";
import SubTitle2 from "../../DS/Typo/SubTitle2";
import Wrapper from "../../DS/Wrapper/Wrapper";
import EmailSubscribe from "../EmailSubscribe/EmailSubscribe";
import LightDivider from "../LightDivider/LightDivider";
import TriColorDivider from "../TriColorDivider/TriColorDivider";
import mark from "../../assets/images/mark.png";
const MarkImg = styled.img`
  position: absolute;
   top: 90%;
    left: 0;
    width: 110px;
    
`;
const JoinAERAContainer = styled.div``;

const JoinAERACardDetails = [
  {
    title: "AERA/AEA Benefits",
    subtitle: "www.myaea.org/benefits",
    url: "https://www.myaea.org/benefits",
    boxUrl:
      "https://www.myaea.org/benefits",
  },
  {
    title: "AERA/AMBA Benefits",
    subtitle: "www.myambabenefits.info/aera",
    url: "https://www.myambabenefits.info/aera",
    boxUrl:
      "https://www.myambabenefits.info/aera",
  },
  {
    title: "NEA Benefits",
    subtitle: "www.neamb.com",
    url: "https://www.neamb.com",
    boxUrl: "https://www.neamb.com",
  },
  {
    title: "NEA Life Insurance Protection",
    subtitle:
      "www.neamb.com/life-insurance-protection",
    url: "https://www.neamb.com/life-insurance-protection",
    boxUrl:
      "https://www.neamb.com/life-insurance-protection",
  }
];

const JoinAERACardContainer = styled(Link)`
  background-color: ${DS.colors.white};
  transition: all 0.3s ease;
  height: 178px;
  margin-bottom: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-shadow: 0px 4px 13px
    ${Color(DS.colors.darkPurple)
      .rgb()
      .alpha(0.3)
      .toString()};
  & > p {
    transition: all 0.3s ease;
  }
`;

const JoinAERACardLink = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  .join-aera-card-link {
    transition: all 0.3s ease;
  }

  .join-aera-card:hover {
    background-color: ${DS.colors.magenta};
    & > p {
      color: ${DS.colors.white};
    }
  }

  .join-aera-card-link:hover {
    color: ${DS.colors.darkPurple};
  }
`;

const JoinAERACard: FC<{
  title: string;
  subtitle: string;
  url: string;
  boxUrl: string;
}> = ({ title, subtitle, url, boxUrl }) => {
  const screen = useScreenClass();
  const isSmall = ["sm", "xs"].includes(screen);
  return (
    <JoinAERACardLink
      style={{
        width: isSmall
          ? "calc(100% / 2 - 20px)"
          : "calc(100% / 5 - 32px)",
        minWidth: isSmall ? 120 : 140,
        marginLeft: isSmall ? 10 : 16,
        marginRight: isSmall ? 10 : 16,
        marginBottom: isSmall ? 20 : 54,
      }}
    >
      <JoinAERACardContainer
        className="join-aera-card"
        to={{
          pathname: boxUrl,
        }}
        target="_blank"
        rel="noopener"
      >
        <SubTitle1
          style={{
            textAlign: "center",
            maxWidth: 130,
          }}
        >
          {title}
        </SubTitle1>
      </JoinAERACardContainer>

      <Link
        to={{
          pathname: url,
        }}
        target="_blank"
        rel="noopener"
      >
        <Body1
          className="join-aera-card-link"
          fontColor="magenta"
          style={{
            fontStyle: "italic",
            textAlign: "center",
            overflowWrap: "anywhere",
            fontWeight: 300,
          }}
        >
          {subtitle}
        </Body1>
      </Link>
    </JoinAERACardLink>
  );
};

const details = [
  "AERA advocates full funding of the TRS retirement system every year. ",
  "AERA is the only association advocating for future bonus checks and COLA’s for education retirees.",
  "AERA offers discounts on insurance plans, vision, dental, traveling, and other programs through group insurance products.",
  "Members save up to 50 percent at local and national merchants with an ACCESS discount membership card offered through AEA.",
  "Members have access to an attorney referral program with a 30 percent discount offered through AEA along with 2 free 30-minute legal consultations every year.",
  "Members have opportunities to participate in free Continuing Education through AEA.",
  "Members receive quarterly The AERA Voice!. This publication is designed to provide exclusive information for education retirees. Members also receive the AEA Alabama School Journal that keeps all education personnel (active and retired) informed on legislative and other issues affecting the education community.",
  "AERA’s advocating efforts have proven successful in obtaining additional monetary gains on behalf of education retirees.",
];

const ListItemContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const ListItemBullet = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: ${DS.colors.darkPurple};
  margin-right: 15px;
  flex-shrink: 0;
  margin-top: 6px;
`;

const ListItem: FC<{ text: string }> = ({
  text,
}) => {
  return (
    <ListItemContainer>
      <ListItemBullet />
      <SubTitle2
        style={{
          fontWeight: 400,
        }}
      >
        {text}
      </SubTitle2>
    </ListItemContainer>
  );
};

const JoinAERA = () => {
  return (
    <JoinAERAContainer>
      <TriColorDivider />
      <Wrapper
        type="m"
        marginTop="55px"
        marginBottom="63px"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <H3 fontColor="magenta">Why Join Us</H3>
        <H2>Members Benefits</H2>
      </Wrapper>
      <Wrapper
        type="m"
        display="flex"
        justifyContent="center"
        style={{
          flexWrap: "wrap",
        }}
      >
        {JoinAERACardDetails.map(
          (detail, index) => {
            return (
              <JoinAERACard
                key={index}
                {...detail}
              />
            );
          }
        )}
      </Wrapper>
      <Wrapper type="m" marginBottom="50px">
        {details.map((text, index) => {
          return (
            <ListItem key={index} text={text} />
          );
        })}
      </Wrapper>
      <LightDivider type="m" />
      <EmailSubscribe />
      <Wrapper
        type="m"
        marginBottom="70px"
      ></Wrapper>
      <MarkImg src={mark} alt="AERA" />
    </JoinAERAContainer>
  );
};

export default JoinAERA;
