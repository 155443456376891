import { useScreenClass } from "@tinyd/usescreenclass";
import { useScreenWidth } from "@tinyd/usescreensize";
import Color from "color";
import React, { FC } from "react";
import { Fade } from "react-awesome-reveal";
import { Parallax } from "react-parallax";
import styled from "styled-components";
import bg from "../../assets/images/AlabamaHouseBG.jpg";
import imgBG from "../../assets/images/LegislatorCardBG.jpg";
import Button from "../../DS/Button/Button";
import DS, { DSColorType } from "../../DS/DS";
import H2 from "../../DS/Typo/H2";
import H3 from "../../DS/Typo/H3";
import Wrapper from "../../DS/Wrapper/Wrapper";
import dotMatrix from "../../assets/images/dot_matrix1.svg";
import { Link } from "react-router-dom";

const LegislatorBGContainer = styled.div`
  background: linear-gradient(
    ${Color("#212121")
        .rgb()
        .alpha(0.8)
        .toString()}
      0%,
    ${Color("#42429a")
        .rgb()
        .alpha(0.7)
        .toString()}
      100%
  );
  height: 520px;
`;

const LegislatorCTAContainer = styled.div`
  position: relative;
  overflow: hidden;
`;

const MatrixDots = styled.img`
  position: absolute;
  left: 10vw;
  bottom: 1px;
  z-index: 1;
`;

const LegislatorBlock = styled.div`
  height: 500px;
  background-color: ${DS.colors.white};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 2;
  display: flex;
  & > div {
    flex: 1;
    display: flex;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    .legislator-text-block {
      display: flex;
      flex-direction: column;
      margin: 0px 43px;
    }
  }

  .legislator-text-block-container {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  position: relative;
  z-index: 3;
  margin-left: auto;
  margin-right: auto;
`;

const LegislatorCTA: FC<{
  backgroundColor?: keyof DSColorType;
  type?: "bottom" | "top";
  hideAdvocacy?: boolean;
}> = ({
  backgroundColor = "white",
  type = "bottom",
  hideAdvocacy = false,
}) => {
  const screenWidth = useScreenWidth();
  const screenClass = useScreenClass();
  const isSmall = screenWidth < 700;
  const isMobile = ["xs", "sm"].includes(
    screenClass
  );
  return (
    <LegislatorCTAContainer
      style={{
        backgroundColor:
          DS.colors[backgroundColor],
      }}
    >
      <Parallax
        strength={-200}
        bgImage={bg}
        style={{
          height: 520,
          position: "absolute",
          width: "100vw",
          zIndex: 1,
          ...(type === "bottom"
            ? { top: 0 }
            : { bottom: 0 }),
        }}
      >
        <LegislatorBGContainer />
      </Parallax>
      <Wrapper
        type="m"
        marginTop={
          type === "bottom" ? "145px" : "40px"
        }
        marginBottom={
          type === "bottom" ? "40px" : "120px"
        }
      >
        <LegislatorBlock
          style={{
            flexDirection: isSmall
              ? "column-reverse"
              : "row",
            ...(isMobile
              ? { paddingBottom: 20 }
              : {}),
          }}
        >
          <div className="legislator-text-block-container">
            <Fade
              direction="left"
              className="legislator-text-block"
              duration={500}
              style={{
                margin: isSmall
                  ? "0px 25px"
                  : "0px 43px",
              }}
              triggerOnce
            >
              <H3 fontColor="magenta">
                Alabama Legislature{" "}
              </H3>
              <H2
                marginTop={
                  isSmall ? "0px" : "12px"
                }
              >
                Find Your Legislator
              </H2>
              <Link
                to={{
                  pathname:
                    "https://pluralpolicy.com/find-your-legislator/?nab=1",
                }}
                target="_blank"
              >
                <Button
                  hoverColor="magenta"
                  marginTop={
                    isSmall ? "0px" : "25px"
                  }
                >
                  Find Your Legislature
                </Button>
              </Link>
            </Fade>
          </div>
          <div>
            <Fade
              direction="right"
              duration={500}
              triggerOnce
            >
              <img src={imgBG} alt="Legislator" />
            </Fade>
          </div>
        </LegislatorBlock>
        <ButtonContainer
          style={{
            width: isMobile ? "100%" : "70%",
            marginTop: "-25px",
            ...(hideAdvocacy
              ? {
                  justifyContent: "center",
                }
              : {}),
          }}
        >
          {!hideAdvocacy ? (
            <Link
              to="/advocacy"
              style={{
                flex: isMobile ? "unset" : "1",

                margin: isMobile
                  ? "0px 5px 0px 0px"
                  : "0px 10px",
                ...(isMobile
                  ? {
                      minWidth: "calc(50% - 5px)",
                    }
                  : {}),
              }}
            >
              <Button
                height="50px"
                bgColor="green"
                shadow
                style={{
                  width: "100%",
                }}
              >
                Advocacy
              </Button>
            </Link>
          ) : null}
          <Link
            to="/contact"
            style={{
              flex: isMobile ? "unset" : "1",
              maxWidth: hideAdvocacy
                ? "50%"
                : "unset",
              margin: isMobile
                ? "0px 0px 0px 5px"
                : "0px 10px",
              ...(isMobile
                ? {
                    minWidth: "calc(50% - 5px)",
                  }
                : {}),
            }}
          >
            <Button
              height="50px"
              bgColor="yellow"
              shadow
              style={{
                width: "100%",
              }}
            >
              Contact Us
            </Button>
          </Link>
        </ButtonContainer>
        <MatrixDots
          src={dotMatrix}
          alt="dot"
          style={
            type === "bottom"
              ? {}
              : {
                  top: 0,
                }
          }
        />
      </Wrapper>
    </LegislatorCTAContainer>
  );
};

export default LegislatorCTA;
