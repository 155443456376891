import {
  useCallback,
  useEffect,
  useState,
} from "react";
import PubSub from "pubsub-js";
const valuesStore: { [key: string]: any } = {};

const getOrSetValue = <T = any>(
  key: string,
  value?: T
) => {
  if (key in valuesStore) {
    return valuesStore[key] as T;
  }
  if (typeof value !== "undefined")
    valuesStore[key] = value;
  return value as T;
};

export function useSetSharedState<T = any>(
  key: string
) {
  const changeValue = useCallback(
    (newValue: T) => {
      PubSub.publish(key, newValue);
    },
    [key]
  );

  return changeValue;
}

function useSharedState<T = any>(
  key: string,
  initialValue?: T
): [T, (newValue: T) => any] {
  const [value, setValue] = useState<T>(
    getOrSetValue(key, initialValue)
  );

  const changeValue = useSetSharedState(key);

  useEffect(() => {
    const id = PubSub.subscribe(
      key,
      (_: string, data: T) => {
        // console.log("got new value", key, data);
        valuesStore[key] = data;
        setValue(data);
      }
    );
    return () => PubSub.unsubscribe(id);
  }, [key, setValue]);

  return [value, changeValue];
}

export default useSharedState;
