import axios from "axios";
import { ENDPOINT } from "../utils/api";
export interface Event {
  id: number;
  date: string;
  date_gmt: string;
  guid: Guid;
  modified: string;
  modified_gmt: string;
  slug: string;
  status: string;
  type: string;
  link: string;
  title: Title;
  content: Content;
  template: string;
  acf: Acf;
  _links: Links;
}

export interface Links {
  self: About[];
  collection: About[];
  about: About[];
  "wp:attachment": About[];
  curies: Cury[];
}

export interface About {
  href: string;
}

export interface Cury {
  name: string;
  href: string;
  templated: boolean;
}

export interface Acf {
  date: string;
}

export interface Content {
  rendered: string;
  protected: boolean;
}

export interface Guid {
  rendered: string;
}

export interface Title {
  rendered: string;
}

const getEvents = (): Promise<Event[]> => {
  //get static data
  return new Promise((resolve, reject) => {
    axios
      .get<Event[]>(
        `${ENDPOINT}/wp/v2/events?order=asc`
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};

export default getEvents;
