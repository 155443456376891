import styled from "styled-components";
import DS from "../DS";

const Input = styled.input`
  border: 1px solid #707070;
  height: 49px;
  padding: 0px 20px;
  font-family: ${DS.fontFamily.NeueHaasUnica};
  font-size: 16px;
  outline: none;
  input::placeholder{
    color: #989898 !important;
}
`;

export default Input;
