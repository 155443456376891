import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import Button from "../../DS/Button/Button";
import DS from "../../DS/DS";
import Input from "../../DS/Input/Input";
import TextArea from "../../DS/TextArea/TextAera";
import Body1 from "../../DS/Typo/Body1";
import H2 from "../../DS/Typo/H2";
import H3 from "../../DS/Typo/H3";
import H4 from "../../DS/Typo/H4";
import Wrapper from "../../DS/Wrapper/Wrapper";
import TriColorDivider from "../TriColorDivider/TriColorDivider";
import navigation from "../../assets/icons/location_red.svg";
import mobile from "../../assets/icons/mobile_dark_red.svg";
import mail from "../../assets/icons/mail_dark_red.svg";
import fax from "../../assets/icons/print.svg";
import { Link } from "react-router-dom";
import { useScreenWidth } from "@tinyd/usescreensize";
import LightDivider from "../LightDivider/LightDivider";
import EmailSubscribe from "../EmailSubscribe/EmailSubscribe";
import submitContact from "../../services/submitContact";
import validateEmail from "../../services/validateEmail";
import Caption from "../../DS/Typo/Caption";

type menuItemLink = {
  url: string;
  text: string;
};

const contactData: (menuItemLink & {
  icon: string;
  imgStyle?: React.CSSProperties;
})[] = [
  {
    icon: navigation,
    text: "828 Washington Ave. Montgomery, AL 36104 United States",
    url: "/",
  },
  {
    icon: mobile,
    text: "1-800-537-6867",
    url: "/",
  },
  {
    icon: fax,
    text: "334-262-6002",
    url: "/",
  },
  {
    icon: mail,
    text: "info@aerainc.org",
    url: "/",
    imgStyle: {
      top: "2px",
      position: "relative",
    },
  },
];

const ContactContainer = styled.div``;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(50% - 50px);
  padding-right: 50px;
  align-items: flex-start;
  input,
  textarea {
    margin-bottom: 20px;
    width: calc(100% - 50px);
  }
`;

const MiniDivider = styled.div`
  height: 3px;
  width: 80px;
  background-color: ${DS.colors.magenta};
`;

const ContactDetailsContainer = styled.div`
  padding-left: 50px;
`;

const ContactDataContainer = styled.div`
  margin-top: 30px;
  & > a {
    display: flex;
    margin-bottom: 17.8px;
    width: 330px;
    img {
      margin-right: 12px;
    }
  }
`;

const Contact = () => {
  const screenWidth = useScreenWidth();
  const timerId = useRef<number>(-1);

  const refName = useRef<HTMLInputElement>(null);
  const refEmail = useRef<HTMLInputElement>(null);
  const refSubject =
    useRef<HTMLInputElement>(null);
  const refMessage =
    useRef<HTMLTextAreaElement>(null);
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [error, setError] = useState<
    [boolean, boolean, boolean, boolean]
  >([false, false, false, false]);
  const [success, setSuccess] = useState(false);
  const isSmall = screenWidth < 880;
  const submit = useCallback(() => {
    const error: [
      boolean,
      boolean,
      boolean,
      boolean
    ] = [false, false, false, false];
    if (
      !refName.current?.value.replace(/ */g, "")
        .length
    ) {
      error[0] = true;
    }
    if (
      !validateEmail(
        refEmail.current?.value || ""
      )
    ) {
      error[1] = true;
    }
    if (
      !refSubject.current?.value.replace(
        / */g,
        ""
      ).length
    ) {
      error[2] = true;
    }
    if (
      !refMessage.current?.value.replace(
        / */g,
        ""
      ).length
    ) {
      error[3] = true;
    }
    setError(error);
    if (error.filter((d) => !d).length === 4) {
      console.log(error);
      setLoading(true);

      submitContact(
        refName.current?.value || "",
        refEmail.current?.value || "",
        refSubject.current?.value || "",
        refMessage.current?.value || ""
      )
        .then(() => {
          setSuccess(true);
          setError([false, false, false, false]);
          setApiError(false);
          refName.current!.value = "";
          refEmail.current!.value = "";
          refSubject.current!.value = "";
          refMessage.current!.value = "";
        })
        .catch((e) => {
          console.log(e);
          setApiError(true);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  useEffect(() => {
    if (error.filter((d) => d).length) {
      clearTimeout(timerId.current);
      timerId.current = setTimeout(() => {
        setError([false, false, false, false]);
      }, 4000) as any as number;
    }
  }, [error]);

  useEffect(() => {
    if (apiError) {
      clearTimeout(timerId.current);
      timerId.current = setTimeout(() => {
        setApiError(false);
      }, 4000) as any as number;
    }
  }, [apiError]);

  useEffect(() => {
    if (success) {
      clearTimeout(timerId.current);
      timerId.current = setTimeout(() => {
        setSuccess(false);
      }, 4000) as any as number;
    }
  }, [success]);

  return (
    <ContactContainer>
      <TriColorDivider />
      <Wrapper type="m" marginTop="50px">
        <H3 fontColor="magenta">Get In Touch</H3>
        <H2>Contact Us</H2>
        <Body1>
          AERA is located in Downtown Montgomery
          on Washington Ave., a few blocks from
          the State Capitol and The Alabama State
          House. If you need any help with
          directions or information, please
          contact us.
        </Body1>
      </Wrapper>
      <Wrapper
        type="m"
        display="flex"
        marginTop="50px"
        marginBottom="100px"
        flexDirection={
          isSmall ? "column-reverse" : "row"
        }
      >
        <FormWrapper
          style={
            isSmall
              ? {
                  paddingRight: 0,
                  marginBottom: 30,
                  width: "100%",
                }
              : {}
          }
        >
          <Input
            ref={refName}
            disabled={loading}
            type="text"
            placeholder="Your Name"
          />
          {error[0] ? (
            <Caption
              marginBottom={
                isSmall ? "10px" : "30px"
              }
              fontColor="magenta"
            >
              Invalid Name
            </Caption>
          ) : null}
          <Input
            ref={refEmail}
            disabled={loading}
            type="email"
            placeholder="Your Email"
          />
          {error[1] ? (
            <Caption
              marginBottom={
                isSmall ? "10px" : "30px"
              }
              fontColor="magenta"
            >
              Invalid Email
            </Caption>
          ) : null}
          <Input
            ref={refSubject}
            disabled={loading}
            type="text"
            placeholder="Subject"
          />
          {error[2] ? (
            <Caption
              marginBottom={
                isSmall ? "10px" : "30px"
              }
              fontColor="magenta"
            >
              Invalid Subject
            </Caption>
          ) : null}
          <TextArea
            ref={refMessage}
            disabled={loading}
            placeholder="Your Message"
          />
          {error[3] ? (
            <Caption
              marginBottom={
                isSmall ? "10px" : "30px"
              }
              fontColor="magenta"
            >
              Invalid Message
            </Caption>
          ) : null}
          {apiError ? (
            <Caption
              marginBottom={
                isSmall ? "10px" : "30px"
              }
              fontColor="magenta"
            >
              Please Try Again!
            </Caption>
          ) : null}
          {success ? (
            <Caption
              marginBottom={
                isSmall ? "10px" : "30px"
              }
              fontColor="green1"
            >
              Successfully Submitted
            </Caption>
          ) : null}
          <Button
            disabled={loading}
            onClick={submit}
          >
            {loading
              ? "Sending..."
              : "Send Message"}
          </Button>
        </FormWrapper>
        <ContactDetailsContainer
          style={
            isSmall
              ? { paddingLeft: 0, width: "100%" }
              : {}
          }
        >
          <H4
            fontFamily="NeueHaasUnica"
            style={{
              fontWeight: 800,
            }}
          >
            Contact Details
          </H4>
          <MiniDivider />
          <ContactDataContainer>
            {contactData.map((link, index) => {
              return (
                <Link to={link.url} key={index}>
                  <img
                    src={link.icon}
                    alt={link.text}
                    style={link.imgStyle}
                  />
                  <Body1
                    style={{ fontWeight: 500 }}
                  >
                    {link.text}
                  </Body1>
                </Link>
              );
            })}
          </ContactDataContainer>
        </ContactDetailsContainer>
      </Wrapper>
      <LightDivider />
      <EmailSubscribe />
      <Wrapper type="m" marginTop="80px" />
    </ContactContainer>
  );
};

export default Contact;
