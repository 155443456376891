import { useScreenClass } from "@tinyd/usescreenclass";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import DS from "../../DS/DS";
import Body1 from "../../DS/Typo/Body1";

const LinkCardContainer = styled(Link)`
  background-color: ${DS.colors.white};

  box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.16),
    inset 0 3px 0px ${DS.colors.magenta};
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.3s ease;
  &:hover {
    box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.2),
      inset 0 8px 0px ${DS.colors.magenta};
  }
  & > p {
    width: calc(100% - 50px);
  }
`;

const LinkCardIconContainer = styled.div`
  height: 155px;
  display: flex;
  justify-content: center;
  align-items: center;

  & > img {
    flex: 1;
    object-fit: scale-down;
    max-width: calc(80% - 20px);
  }
`;

const LinkCard: FC<{
  icon: string;
  text: string;
  link: string;
  hide?: boolean;
}> = ({ icon, text, link, hide }) => {
  const screen = useScreenClass();
  const isSmall = ["xs", "sm"].includes(screen);
  if (hide) {
    return (
      <LinkCardContainer
        to=""
        as={"div" as any}
        style={
          isSmall
            ? {
                display: "none",
              }
            : {
                width:
                  "calc(100% / 3 - (56px / 2))",
                marginBottom: 55,
                height: 220,
                opacity: 0,
              }
        }
      ></LinkCardContainer>
    );
  }
  return (
    <LinkCardContainer
      to={{ pathname: link }}
      target="_blank"
      style={
        isSmall
          ? {
              width: "calc(100% / 1) ",
              marginBottom: 30,
              height: 220 - 20,
            }
          : {
              width:
                "calc(100% / 3 - (56px / 2))",
              marginBottom: 55,
              height: 220,
            }
      }
    >
      <LinkCardIconContainer
        style={
          isSmall
            ? {
                height: 100,
              }
            : {
                height: 155,
              }
        }
      >
        <img
          src={icon}
          alt={text}
          style={
            isSmall
              ? {
                  height: 80,
                }
              : {
                  height: 135,
                }
          }
        />
      </LinkCardIconContainer>
      <Body1
        style={{
          textAlign: "center",
        }}
      >
        {text}
      </Body1>
    </LinkCardContainer>
  );
};

export default LinkCard;
