import React from "react";
import {
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import AEAFoundation from "../../components/AEAFoundation/AEAFoundation";
import BoardOfDirector from "../../components/BoardOfDirector/BoardOfDirector";
import LocalUnits from "../../components/LocalUnits/LocalUnits";
import Staff from "../../components/Staff/Staff";
import StateCommittees from "../../components/StateCommittees/StateCommittees";
import TriColorDivider from "../../components/TriColorDivider/TriColorDivider";
import WhoWeAre from "../../components/WhoWeAre/WhoWeAre";
import Wrapper from "../../DS/Wrapper/Wrapper";

const AboutUsPage = () => {
  return (
    <>
      <TriColorDivider />
      <Wrapper type="m" marginTop="50px" />
      <Switch>
        <Route path="/about/who_we_are" exact>
          <WhoWeAre />
        </Route>
        <Route path="/about/aea_foundation" exact>
          <AEAFoundation />
        </Route>
        <Route
          path="/about/board_of_directors"
          exact
        >
          <BoardOfDirector />
        </Route>
        <Route
          path="/about/state_committees"
          exact
        >
          <StateCommittees />
        </Route>
        <Route path="/about/local_units" exact>
          <LocalUnits />
        </Route>

        <Route path="/about/aera_staff" exact>
          <Staff />
        </Route>
        <Route path="/about">
          <Redirect to="/about/who_we_are" />
        </Route>
      </Switch>
    </>
  );
};

export default AboutUsPage;
