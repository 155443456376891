import { FC } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Body1 from "../../DS/Typo/Body1";
import H2 from "../../DS/Typo/H2";

import Wrapper from "../../DS/Wrapper/Wrapper";
import EmailSubscribe from "../EmailSubscribe/EmailSubscribe";
import LightDivider from "../LightDivider/LightDivider";
import TriColorDivider from "../TriColorDivider/TriColorDivider";
import arrow from "../../assets/icons/downArrowRed.svg";
import DS from "../../DS/DS";
import { useScreenClass } from "@tinyd/usescreenclass";

const DocumentsContainer = styled.div``;

const DocumentsLinksDetails: any[] = [
  {
    title: "AERA State Office Nomination Form for 2025",
    url: "https://www.aerainc.org/wp-content/uploads/2024/10/AERA-State-Office-Nomination-Form-for-2025.docx"
  },
  {
    title: "24 AEA-Retired Form",
    url: "https://www.aerainc.org/wp-content/uploads/2024/10/24-AEA-Retired-Form.pdf"
  },
  {
    title: "24 NEA-Retired Form",
    url: "https://www.aerainc.org/wp-content/uploads/2024/10/24-NEA-Retired-Form.pdf"
  },
  {
    title: "Biography Form for election 75 words",
    url: "https://www.aerainc.org/wp-content/uploads/2024/10/Biography-Form-for-election-75-words.docx"
  },
  {
    title: "Summary of 2025 Elections",
    url: "https://www.aerainc.org/wp-content/uploads/2024/10/Summary-of-2025-Elections.doc"
  },
  {
    title: "2024-2025 Election Timeline",
    url: "https://www.aerainc.org/wp-content/uploads/2024/10/2024-2025-Election-Timeline.docx"
  }
];

const DocumentsLinksContainer = styled(Link)`
  background-color: ${DS.colors.white};
  padding: 35px 50px;
  box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.16);
  transition: all 0.3s ease;
  display: flex;
  & > p {
    transition: all 0.3s ease;
    flex: 1;
  }
  & > img {
    transform: rotate(-90deg);
    margin-left: 20px;
  }
  &:hover {
    background-color: ${DS.colors.magenta};
    & > p {
      color: ${DS.colors.white};
    }
    & > img {
      filter: brightness(10);
    }
  }
`;

const DocumentsLink: FC<{
  title: string;
  url: string;
}> = ({ title, url }) => {
  const screen = useScreenClass();
  const isSmall = ["xs", "sm"].includes(screen);
  return (
    <DocumentsLinksContainer
      to={{
        pathname: url,
      }}
      target="_blank"
      style={{
        marginBottom: 30,
      }}
    >
      <Body1>{title}</Body1>
      <img src={arrow} alt={title} />
    </DocumentsLinksContainer>
  );
};

const Documents = () => {
  return (
    <DocumentsContainer>
      <TriColorDivider />
      <Wrapper type="m" marginTop="50px">
        <H2>Links</H2>
      </Wrapper>
      <Wrapper
        type="m"
        display="flex"
        marginTop="50px"
        style={{
          flexDirection: "column",
        }}
      >
        {DocumentsLinksDetails.map(
          (
            link: { url: string; title: string },
            index: number
          ) => {
            return <DocumentsLink key={index} {...link} />;
          }
        )}
      </Wrapper>
      <LightDivider marginTop="60px" />
      <EmailSubscribe />
      <Wrapper marginTop="60px" />
    </DocumentsContainer>
  );
};

export default Documents;
