import styled from "styled-components";
import BreakPointsFontsMedia from "../../utils/BreakPointsFontsMedia";
import DS from "../DS";
import {
  BaseTypoCSS,
  BaseTypoProps,
} from "./BaseTypo";

const H3 = styled.p<BaseTypoProps>`
  ${BaseTypoCSS({
    fontFamily: "Parisienne",
  })}
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  line-height: 33px;
  letter-spacing: 0;
  ${BreakPointsFontsMedia(
    DS.breakpoints,
    34,
    21,
    12
  )};
`;

export default H3;
