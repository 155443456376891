import React, { FC } from "react";
import { Parallax } from "react-parallax";
import styled from "styled-components";
import heroBG from "../../assets/images/scholarships_bg.jpg";
import DS from "../../DS/DS";
import Color from "color";

import GoogleMapReact from "google-map-react";
import { GOOGLE_KEY } from "../../utils/config";
import logo from "../../assets/images/logo.png";
import Body1 from "../../DS/Typo/Body1";
import { Link } from "react-router-dom";

const HeroContactContainer = styled.div`
  height: 492px;
  background: linear-gradient(
    0deg,
    ${Color(DS.colors.darkPurple)
        .rgb()
        .alpha(0.99)
        .toString()}
      10%,
    ${Color(DS.colors.darkPurple)
        .rgb()
        .alpha(0)
        .toString()}
      100%
  );
  display: flex;
  align-items: center;
`;

const CustomMarkerContainer = styled.div`
  background-color: ${DS.colors.white};
  padding: 20px;
  border-radius: 10px;
  width: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & > p {
    margin-top: 20px;
  }
`;

const CustomMarker: FC<{
  lat: number;
  lng: number;
  text: string;
}> = () => {
  return (
    <Link
      to={{
        pathname:
          "https://www.google.com/maps/place/Alabama+Education+Retirees/@32.3760645,-86.2994017,17z/data=!3m1!4b1!4m5!3m4!1s0x888e8176025aec43:0x836d08d3f9788a14!8m2!3d32.37606!4d-86.297213",
      }}
      target="_blank"
    >
      <CustomMarkerContainer>
        <img
          src={logo}
          alt="AERA"
          style={{
            width: 100,
          }}
        />
        <Body1>Get Directions</Body1>
      </CustomMarkerContainer>
    </Link>
  );
};

const HeroContact = () => {
  return (
    <Parallax
      bgImage={heroBG}
      strength={-100}
      bgImageStyle={{
        height: 1000,
        objectFit: "cover",
      }}
    >
      <HeroContactContainer>
        <GoogleMapReact
          style={{
            height: 492,
            width: "100%",
          }}
          bootstrapURLKeys={{
            key: GOOGLE_KEY,
          }}
          defaultZoom={18}
          center={{
            lat: 32.3760597,
            lng: -86.2977812,
          }}
        >
          <CustomMarker
            text="AERA"
            lat={32.3760597}
            lng={-86.2977812}
          />
        </GoogleMapReact>
      </HeroContactContainer>
    </Parallax>
  );
};

export default HeroContact;
