// import useScroll from "@tinyd/usescroll";
import React, {
  FC,
  // useEffect,
  useRef,
  // useState,
} from "react";
import styled from "styled-components";
import Wrapper from "../../DS/Wrapper/Wrapper";
// import ScaleRangeNumber from "../../utils/ScaleRangeNumber";
import dotImg from "../../assets/images/dot_maxtix.svg";
import { useScreenWidth } from "@tinyd/usescreensize";
import { Fade } from "react-awesome-reveal";

const Img = styled.img`
  position: absolute;
  object-fit: cover;
  object-position: center top;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
`;

const GridImg = styled.img`
  position: absolute;
  right: -90px;
  width: 400px;
  bottom: -50px;
  z-index: -1;
  overflow: clip;
`;

const HomeSplitImage: FC<{
  imgs: [string, string];
}> = ({ imgs }) => {
  // const scroll = useScroll();
  const width = useScreenWidth();
  const isSmall = width < 880;
  const height = isSmall ? 250 : 490;
  const imgHeight = isSmall ? 250 : 460;
  const ref = useRef<HTMLDivElement>(null);
  // const [step, setStep] = useState(0);
  // useEffect(() => {
  //   if (ref.current) {
  //     // console.log(
  //     //   scroll.scrollDirection,
  //     //   scroll.scrollY,
  //     //   window.innerHeight -
  //     //     ref.current.getBoundingClientRect().y
  //     // );
  //     if (
  //       window.innerHeight -
  //         ref.current.getBoundingClientRect().y >
  //       0
  //     ) {
  //       setStep(
  //         window.innerHeight -
  //           ref.current.getBoundingClientRect().y
  //       );
  //     }
  //   }
  // }, [scroll, setStep, ref]);
  return (
    <div
      style={{
        width,
      }}
    >
      <Wrapper
        ref={ref}
        type="l"
        style={{
          position: "relative",
          marginBottom: isSmall ? "10px" : "68px",
        }}
        noSmTriggers
        display="flex"
        marginTop={isSmall ? "40px" : "0px"}
        height={`${height}px`}
      >
        <Fade cascade triggerOnce>
          <Img
            src={imgs[0]}
            alt="left"
            style={{
              height: imgHeight,
              width: isSmall
                ? "50%"
                : "calc(50% + 30px)",
              left: 0,
              top: isSmall ? 0 : 90,
            }}
          />
          <Img
            src={imgs[1]}
            alt="right"
            style={{
              height: imgHeight,

              width: isSmall
                ? "50%"
                : "calc(50% + 30px)",
              right: 0,
              top: 0,
            }}
          />
        </Fade>
        <GridImg src={dotImg} alt="grid"  style={{ bottom: '-90px' }}/>
      </Wrapper>
    </div>
  );
};

export default HomeSplitImage;
