import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSetSharedState } from "../../hooks/useSharedState/useSharedState";
import styled from "styled-components";
import TriColorDivider from "../TriColorDivider/TriColorDivider";
import Wrapper from "../../DS/Wrapper/Wrapper";
import LightDivider from "../LightDivider/LightDivider";
import EmailSubscribe from "../EmailSubscribe/EmailSubscribe";
import { NotionRenderer } from "react-notion";
import Body1 from "../../DS/Typo/Body1";
import H2 from "../../DS/Typo/H2";
import H3 from "../../DS/Typo/H3";
import H4 from "../../DS/Typo/H4";
import SubTitle1 from "../../DS/Typo/SubTitle1";
const NewsContainer = styled.div``;

export const Jobs: FC = () => {
  const [loading, setLoading] = useState(true);
  const { id } = useParams<{ id: string }>();
  const [job, setJob] = useState<any>();
  const setTitle = useSetSharedState("news_title");
  const setBanner = useSetSharedState("news_banner");
  const setDate = useSetSharedState("news_date");
  useEffect(() => {
    if (!job)
      fetch(`https://notion-api.splitbee.io/v1/page/${id}`)
        .then((job) => {
          job
            .json()
            .then((res) => {
              setJob(res);
              setLoading(false);
              console.log(res);
              Object.keys(res).forEach((key, index) => {
                console.log(key, res[key]);
                if ("value" in res[key] && index === 0) {
                  try {
                    setTitle(
                      res[
                        key
                      ].value.properties.title[0].join(" ")
                    );
                  } catch (err) {
                    console.log(err);
                  }
                  console.log(
                    res[key].value.parent_table,
                    res[key].value.type,
                    res[key].value.properties.title[0].join(
                      " "
                    )
                  );
                }
              });
              // setTitle(
              //   news.title.rendered.replace("&#8230;", "...")
              // );
            })
            .catch((err) => {
              console.log(err);
              alert("Not Found");
            });

          // setBanner(news.acf.news_banner.url);
          // setDate(dayjs(news.date).format("MMMM D, YYYY"));
        })
        .catch((err) => {
          console.log(err);
          alert("Not Found");
        });
  }, [id, setTitle, job, setBanner, setDate]);
  return (
    <NewsContainer>
      <TriColorDivider loading={loading} />
      <Wrapper type="m" marginTop="60px">
        {!loading && job ? (
          <NotionRenderer
            blockMap={job}
            customBlockComponents={{
              quote: (props) => (
                <div>
                  <Body1 as="div">
                    {props.renderComponent()}
                  </Body1>
                </div>
              ),
              header: (props) => (
                <H3
                  marginTop="15px"
                  marginBottom="15px"
                  fontFamily="NeueHaasUnica"
                >
                  {props.renderComponent()}
                </H3>
              ),
              sub_header: (props) => (
                <H4 marginTop="10px" marginBottom="10px">
                  {props.renderComponent()}
                </H4>
              ),
              sub_sub_header: (props) => (
                <SubTitle1
                  marginTop="10px"
                  marginBottom="10px"
                >
                  {props.renderComponent()}
                </SubTitle1>
              ),
              column_list: (props) => (
                <Body1 as="li" {...props}>
                  {props.renderComponent()}
                </Body1>
              ),
              bulleted_list: (props) => (
                <Body1 as="ul" {...props}>
                  {props.renderComponent()}
                </Body1>
              ),
              numbered_list: (props) => (
                <Body1 as="ol" {...props}>
                  {props.renderComponent()}
                </Body1>
              ),
              text: (props) => (
                <Body1 as="span" {...props}>
                  {props.renderComponent()}
                </Body1>
              ),
            }}
          />
        ) : // <NewsDetails data={news} />
        null}
      </Wrapper>
      <LightDivider marginTop="100px" type="l" />
      <EmailSubscribe />
      <Wrapper type="m" marginTop="80px" />
    </NewsContainer>
  );
};
