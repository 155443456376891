export type DSColorType = {
  blue: string;
  yellow: string;
  darkPurple: string;
  gray: string;
  dimGray: string;
  dimGray1: string;
  dimGray2: string;
  lightGray: string;
  lightGray1: string;
  lightGray2: string;
  lightGray3: string;
  dimmedPurple: string;
  white: string;
  black: string;
  magenta: string;
  green: string;
  green1: string;
};

export type DSFontFamily = {
  NeueHaasUnica: string;
  Parisienne: string;
};
export type DSWrapper = {
  xl: number;
  l: number;
  m: number;
  sm: number;
};
export type DSTypes = {
  colors: DSColorType;
  fontFamily: DSFontFamily;
  breakpoints: number[];
  wrappers: DSWrapper;
  wrapperInnerPadding: number;
  wrapperInnerSmTriggerWidth: number;
};
const DS: DSTypes = {
  colors: {
    blue: "#34A7FF",
    yellow: "#F9BA28",
    darkPurple: "#2B2B64",
    gray: "#949587",
    dimGray: "#C9C9C9",
    dimGray1: "#898A7C",
    dimGray2: "#989898",
    lightGray: "#F2F2F2",
    lightGray1: "#F1F1F1",
    lightGray2: "#EAEAEA",
    lightGray3: "#F8F8F8",
    dimmedPurple: "#555589",
    white: "#FFFFFF",
    black: "#000000",
    magenta: "#C82C51",
    green: "#7FC15E",
    green1: "#388e3c",
  },
  fontFamily: {
    NeueHaasUnica: "Neue Haas Unica",
    Parisienne: "Parisienne",
  },
  breakpoints: [
    360, 400, 480, 600, 720, 840, 960, 1024, 1280,
    1440,
  ],
  wrappers: {
    xl: 1710,
    l: 1397,
    m: 1161,
    sm: 302,
  },
  wrapperInnerPadding: 30,
  wrapperInnerSmTriggerWidth: 600,
};

export default DS;
