import styled from "styled-components";
import BreakPointsFontsMedia from "../../utils/BreakPointsFontsMedia";
import DS from "../DS";
import {
  BaseTypoCSS,
  BaseTypoProps,
} from "./BaseTypo";

const H1 = styled.p<BaseTypoProps>`
  ${BaseTypoCSS({
    fontFamily: "NeueHaasUnica",
  })}
  font-style: normal;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0;
  font-weight: 800;
  ${BreakPointsFontsMedia(
    DS.breakpoints,
    60,
    32,
    8
  )};
`;

export default H1;
