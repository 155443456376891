import React, { useCallback } from "react";
import { Parallax } from "react-parallax";
import styled from "styled-components";
import about2 from "../../assets/images/about2.jpg";
import about1 from "../../assets/images/about1.jpg";
import about3 from "../../assets/images/about3.jpg";
import about4 from "../../assets/images/about4.jpg";
import about5 from "../../assets/images/about5.jpg";
import DS from "../../DS/DS";
import Color from "color";
import { Fade } from "react-awesome-reveal";
import H1 from "../../DS/Typo/H1";
import Wrapper from "../../DS/Wrapper/Wrapper";
import {
  Link,
  useLocation,
} from "react-router-dom";
import Body1 from "../../DS/Typo/Body1";
import H4 from "../../DS/Typo/H4";
import { useScreenClass } from "@tinyd/usescreenclass";

const HeroAboutContainer = styled.div`
  height: 492px;
  background: linear-gradient(
    0deg,
    ${Color(DS.colors.darkPurple)
        .rgb()
        .alpha(0.99)
        .toString()}
      10%,
    ${Color(DS.colors.darkPurple)
        .rgb()
        .alpha(0)
        .toString()}
      100%
  );
  display: flex;
  align-items: center;
`;

const bgData = {
  who_we_are: about2,
  board_of_directors: about1,
  aea_foundation: about2,
  state_committees: about3,
  local_units: about4,
  aera_staff: about5,
};

const textData = {
  who_we_are: "Who We Are",
  board_of_directors: "Board of Directors",
  aea_foundation: "AER Foundation",
  state_committees: "State Committees",
  local_units: "Local Units",
  aera_staff: "Staff",
};

const aboutLinks = [
  {
    text: "Who We Are",
    url: "/about/who_we_are",
  },
  {
    text: "Board of Directors",
    url: "/about/board_of_directors",
  },
  {
    text: "AER Foundation",
    url: "/about/aea_foundation",
  },
  {
    text: "State Committees",
    url: "/about/state_committees",
  },
  {
    text: "Local Units",
    url: "/about/local_units",
  },
  {
    text: "Staff",
    url: "/about/aera_staff",
  },
];

const LinkWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  flex-wrap: wrap;
`;

const HeroAbout = () => {
  const location = useLocation();
  const path = location.pathname;
  const screen = useScreenClass();
  const isSmall = ["xs", "sm"].includes(screen);

  const getBG = useCallback((path: string) => {
    let bg = about2;
    Object.keys(bgData).forEach((key) => {
      if (path.includes(key)) {
        bg = bgData[key as keyof typeof bgData];
      }
    });
    return bg;
  }, []);
  const getTitle = useCallback((path: string) => {
    let title = "Who We Are";
    Object.keys(textData).forEach((key) => {
      if (path.includes(key)) {
        title =
          textData[key as keyof typeof textData];
      }
    });
    return title;
  }, []);

  const isActive = useCallback(
    (path: string, link: string) => {
      if (
        path.replace(/ \//g, "") ===
        link.replace(/ \//g, "")
      ) {
        return true;
      }

      return false;
    },
    []
  );

  return (
    <Parallax
      bgImage={getBG(path)}
      strength={-100}
      bgImageStyle={{
        height: 700,
        objectFit: "cover",
      }}
    >
      <HeroAboutContainer>
        <Wrapper
          type="m"
          display="flex"
          flexDirection="column"
        >
          <Fade
            direction="up"
            triggerOnce
            cascade
            duration={500}
          >
            <Body1
              fontColor="white"
              style={{
                textAlign: "center",
              }}
            >
              About Us
            </Body1>
            <H1
              fontColor="white"
              style={{
                textAlign: "center",
              }}
            >
              {getTitle(path)}
            </H1>
            <LinkWrapper>
              {aboutLinks.map((link, index) => {
                return (
                  <Link
                    to={link.url}
                    key={index}
                    style={{
                      width: isSmall
                        ? "100%"
                        : "unset",
                      marginBottom: isSmall
                        ? 20
                        : 40,
                    }}
                  >
                    <H4
                      fontColor="white"
                      style={{
                        textAlign: "center",
                        fontWeight: isActive(
                          path,
                          link.url
                        )
                          ? 500
                          : 300,
                        color: isActive(
                          path,
                          link.url
                        )
                          ? DS.colors.yellow
                          : DS.colors.white,
                      }}
                    >
                      {link.text}
                    </H4>
                  </Link>
                );
              })}
            </LinkWrapper>
          </Fade>
        </Wrapper>
      </HeroAboutContainer>
    </Parallax>
  );
};

export default HeroAbout;
