import ScaleRangeNumber from "./ScaleRangeNumber";
const BreakPointsFontsMedia = (
  breakpoints: number[],
  maxFontSize: number,
  minFontSize: number,
  lineHeightSpacing: number
) => {
  return breakpoints
    .slice(1)
    .map((breakpoint) => {
      let size = Math.round(
        ScaleRangeNumber(
          breakpoint,
          breakpoints[0],
          breakpoints[breakpoints.length - 1],
          minFontSize,
          maxFontSize
        )
      );
      return `@media(min-width: ${breakpoint}px) {
                  font-size: ${size}px;
                line-height: ${
                  size + lineHeightSpacing
                }px;
              }`;
    })
    .join("");
};

export default BreakPointsFontsMedia;
