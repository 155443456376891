import React from "react";
import { Parallax } from "react-parallax";
import styled from "styled-components";
import heroBG from "../../assets/images/Scholarship_bg.jpeg";
import DS from "../../DS/DS";
import Color from "color";
import { Slide, Fade } from "react-awesome-reveal";
import H1 from "../../DS/Typo/H1";
import Wrapper from "../../DS/Wrapper/Wrapper";

const HeroScholarshipsContainer = styled.div`
  height: 492px;
  background: linear-gradient(
    0deg,
    ${Color(DS.colors.darkPurple).rgb().alpha(0.99).toString()} 10%,
    ${Color(DS.colors.darkPurple).rgb().alpha(0).toString()} 100%
  );
  display: flex;
  align-items: center;
`;
const HeroScholarships = () => {
  return (
    <Parallax
      bgImage={heroBG}
      strength={-100}
      bgImageStyle={{
        height: 1000,
        objectFit: "cover",
      }}
    >
      <HeroScholarshipsContainer>
        <Wrapper type="m">
          <Slide direction="up" triggerOnce cascade duration={500}>
            <Fade triggerOnce>
              <H1
                fontColor="white"
                style={{
                  textAlign: "center",
                }}
              >
                Scholarships
              </H1>
            </Fade>
          </Slide>
        </Wrapper>
      </HeroScholarshipsContainer>
    </Parallax>
  );
};

export default HeroScholarships;
