const dev_endpoint = "https://aerainc.org";
// "https://aera.dev-staging-lab.com";
// const prod_endpoint =
//   "https://aera.dev-staging-lab.com";
const prod_endpoint = "https://aerainc.org";

const isDev = () => {
  const path = window.location.host;
  return (
    path.includes("localhost") ||
    path.includes("dev-staging-lab")
  );
};

export const ENDPOINT = `${
  isDev() ? dev_endpoint : prod_endpoint
}/wp-json`;
