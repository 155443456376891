import { useScreenWidth } from "@tinyd/usescreensize";
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import Button from "../../DS/Button/Button";
import DS from "../../DS/DS";
import Input from "../../DS/Input/Input";
import Caption from "../../DS/Typo/Caption";
import H4 from "../../DS/Typo/H4";
import Wrapper from "../../DS/Wrapper/Wrapper";
import subscribeEmail from "../../services/subscribeEmail";
import validateEmail from "../../services/validateEmail";
const EmailWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const InputWrapper = styled.div`
  width: calc(100% - 30px);
  margin-right: 30px;
`;
const FormWrapper = styled.div`
  width: 50%;
  input {
    width: calc(100% - 40px);
  }
  & > div {
    display: flex;
  }
  &::placeholder {
    font-family: ${DS.fontFamily.NeueHaasUnica};
  }
`;
const EmailSubscribe = () => {
  const input = useRef<HTMLInputElement>(null);
  const timerId = useRef<number>(-1);
  const [showError, setError] = useState(false);
  const [showSuccess, setSuccess] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const width = useScreenWidth();
  const isSmall = width < 880;
  useEffect(() => {
    if (showError) {
      clearTimeout(timerId.current);
      timerId.current = setTimeout(() => {
        setError(false);
      }, 2000) as any as number;
    }
  }, [showError, setError]);
  useEffect(() => {
    if (showSuccess) {
      setError(false);
      clearTimeout(timerId.current);
      timerId.current = setTimeout(() => {
        setSuccess(false);
      }, 5000) as any as number;
    }
  }, [showSuccess, setSuccess, setError]);
  const subscribe = useCallback(() => {
    if (input.current) {
      if (validateEmail(input.current.value)) {
        setLoading(true);
        subscribeEmail(
          input.current.value
        ).finally(() => {
          input.current!.value = "";
          setLoading(false);
          setSuccess(true);
        });
      } else {
        setError(true);
      }
    }
  }, [input, setError, setLoading, setSuccess]);
  return (
    <Wrapper
      type="m"
      marginTop={isSmall ? "60px" : "100px"}
      marginBottom={isSmall ? "60px" : "100px"}
    >
      <EmailWrapper>
        <H4
          style={{
            fontWeight: "bold",
            width: isSmall
              ? "100%"
              : "calc(50% - 20px)",
            marginRight: 20,
            justifyContent: "center",
            textAlign: isSmall
              ? "center"
              : "left",
          }}
        >
          Subscribe to our newsletter and stay
          updated on the latest news
        </H4>
        <FormWrapper
          style={{
            width: isSmall ? "100%" : "50%",
            marginTop: isSmall ? 20 : 0,
          }}
        >
          <div
            style={{
              flexDirection: isSmall
                ? "column"
                : "row",
            }}
          >
            <InputWrapper
              style={
                isSmall
                  ? {
                      display: "flex",
                      marginRight: 0,
                      justifyContent: "center",
                      flexDirection: "column",
                      width: "100%",
                    }
                  : {}
              }
            >
              <Input
                disabled={loading}
                type="email"
                placeholder="Enter your email"
                ref={input}
              />
              {showError ? (
                <Caption
                  marginTop={
                    isSmall ? "10px" : "30px"
                  }
                  fontColor="magenta"
                >
                  Invalid Email Address
                </Caption>
              ) : null}
              {showSuccess ? (
                <Caption
                  marginTop={
                    isSmall ? "10px" : "30px"
                  }
                  fontColor="green1"
                >
                  Successfully subscribed to
                  newsletter
                </Caption>
              ) : null}
              <Caption
                marginTop={
                  isSmall ? "10px" : "30px"
                }
                style={{
                  fontWeight: "normal",
                  textAlign: isSmall
                    ? "center"
                    : "left",
                }}
                fontColor="dimGray2"
              >
                By Clicking subscribe I agree to
                my email being stored and used to
                receive the newsletter.
              </Caption>
            </InputWrapper>

            <Button
              disabled={loading}
              onClick={subscribe}
              hoverColor="magenta"
              marginTop={isSmall ? "20px" : "0px"}
              style={
                isSmall
                  ? {
                      width: "200px",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }
                  : {}
              }
            >
              {loading
                ? "Subscribing"
                : "Subscribe"}
            </Button>
          </div>
        </FormWrapper>
      </EmailWrapper>
    </Wrapper>
  );
};

export default EmailSubscribe;
