import React, {
  useCallback,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import H2 from "../../DS/Typo/H2";
import Wrapper from "../../DS/Wrapper/Wrapper";
import bod from "../../assets/images/bod.png";
import EmailSubscribe from "../EmailSubscribe/EmailSubscribe";
// import LightDivider from "../LightDivider/LightDivider";
import ScrollContainer from "react-indiana-drag-scroll";
import SubTitle2 from "../../DS/Typo/SubTitle2";
import DS from "../../DS/DS";
import Table from "../Table/Table";
import Body1 from "../../DS/Typo/Body1";
import H4 from "../../DS/Typo/H4";
import navigation from "../../assets/icons/location_red.svg";
import mobile from "../../assets/icons/mobile_dark_red.svg";
import mail from "../../assets/icons/mail_dark_red.svg";
import { Link } from "react-router-dom";
import mark from "../../assets/images/mark.png";
const MarkImg = styled.img`
  position: absolute;
  top: 200%;
  right: 0;
  width: 110px;
`;
const LinkContainer = styled.div`
  margin-top: 10px;
  & > a,
  & > div {
    display: flex;
    margin-bottom: 10px;
    img {
      margin-right: 12px;
      width: 14px;
      height: 16px;
      margin-top: 6px;
    }
  }
`;

const CustomLink = styled(Link)`
  color: inherit;
`;

const data = [
  {
    title: "Community Service",
    data: [
      {
        index: "1",
        unit: "Lawrence",
        name: "Dr. Delaina Greene",
        address:
          "21990 AL Hwy 157, Town Creek, AL 35672",
        phone: ["256-710-0866"],
        email: "delainagreene@gmail.com",
        first: "2023",
        second: "",
        ends: "2026 (1)",
      },
      {
        index: "2",
        unit: "Madison",
        name: "Otis Threatt",
        address:
          "4049 Knollbrook Drive, Huntsville, AL 35810-1831",
        phone: ["256-852-7074"],
        email: "ozthreatt@comcast.net",
        first: "2018",
        second: "2021",
        ends: "2024 (2)",
      },
      {
        index: "3",
        unit: "Lamar (Vice Chair)",
        name: "Icie Wriley",
        address:
          "170 Grove Street, Millport, AL 35576",
        phone: ["205-662-4766"],
        email: " wriley3@hotmail.com",
        first: "2022",
        second: "",
        ends: "2025 (1)",
      },
      {
        index: "4",
        unit: "Walker",
        name: "Shirley Mitchell",
        subName: "",
        address:
          "PO Box 2670, Jasper, AL 35502",
        phone: ["205-717-4027"],
        email: "AKASAM55@att.net",
        first: "2023",
        second: "",
        ends: "2026 (1)",
      },
      {
        index: "5",
        unit: "Lee (Secretary)",
        name: "Kathy Killcreas",
        address:
          "1808 Hanson Street, Opelika, AL 36801",
        phone: ["251-404-6862"],
        email: "kbkillcreas@gmail.com",
        first: "2021",
        second: "",
        ends: "2024 (1)",
      },
      {
        index: "6",
        unit: "Dallas",
        name: "Jeannie Ward",
        address:
          "301 Ruth Street, Selma, AL 36701",
        phone: ["334-505-1617", "334-216-5335"],
        email: "Jeanie_ward@yahoo.com",
        first: "2022",
        second: "",
        ends: "2025 (1)",
      },
      {
        index: "7",
        unit: "Montgomery",
        name: "Juanita Tucker",
        address:
          "307 Burgwyn Road, Montgomery, AL 36105-3108",
        phone: ["334-303-3204"],
        email: "maxine2@charter.net",
        first: "2023",
        second: "",
        ends: "2026 (1)",
      },
      {
        index: "8",
        unit: "Mobile (Chair)",
        name: "Violetta Simpson",
        address:
          "603 Delaware Street, Mobile, AL 36603",
        phone: ["251-895-2308"],
        email: "vnattasimpson@gmail.com",
        first: "2021",
        second: "",
        ends: "2024 (1)",
      },
      {
        index: "9",
        unit: "Dale",
        name: "Dinah Woodham",
        address:
          "992 County Road 531, Midland City, AL 36350",
        phone: ["334-718-4927"],
        email: "dinahsue@purelife.net",
        first: "2019",
        second: "2022",
        ends: "2025(2)",
      },
      {
        index: "10",
        unit: "TUARA",
        name: "Bobby Ray Hicks",
        address:
          "502 Beacon Ridge Road, Tuscaloosa, AL 35406",
        phone: ["205-752-4802", "205-242-4963"],
        email: "hicksbobbyray@gmail.com",
        first: "2023",
        second: "",
        ends: "2026 (1)",
      },
      {
        index: "",
        unit: "Liaison",
        name: "Peggy Mobley",
        subName: "AERA Treasurer",
        address:
          "983 Mitchell Road, Brantley, AL 36009",
        phone: ["334-312-3258"],
        email: "phmobley46@gmail.com",
        first: "",
        second: "",
        ends: "",
      },
    ],
  },
  {
    title: "Constitution & Bylaws",
    data: [
      {
        index: "1",
        unit: "Limestone",
        name: "Pat Gartman",
        address:
          "405 Bullington Road, Athens, AL 35611",
        phone: ["256-777-7153"],
        email: "gartmanpat347@gmail.com",
        first: "2023",
        second: "",
        ends: "2026 (1)",
      },
      {
        index: "2",
        unit: "Marshall (Secretary)",
        name: "Tricia Hopper",
        address:
          "902 Leyland Drive, Albertville, AL 35950",
        phone: ["256-506-6216"],
        email: "tlhopper.rdb@gmail.com",
        first: "2021",
        second: "",
        ends: "2024 (1)",
      },
      {
        index: "3",
        unit: "Sumter",
        name: "Eleanor James",
        address: "PO Box 1235, Livingston, AL 35470",
        phone: ["205-499-9566"],
        email: "eleanorjames53@yahoo.com",
        first: "2022",
        second: "",
        ends: "2025 (1)",
      },
      {
        index: "4",
        unit: "Jefferson",
        name: "David L. Foster",
        address:
          "644 Bienville Lane, Birmingham, AL 35213",
        phone: ["205-568-7151"],
        email: "fosterdl@bellsouth.net",
        first: "2020",
        second: "2023",
        ends: "2026 (2)",
      },
      {
        index: "5",
        unit: "Autauga",
        name: "Gloria Kuykendall",
        address:
          "1210 Upper Kingston Road, Prattville, AL 36067",
        phone: ["334-467-6165"],
        email: "gck1210@gmail.com",
        first: "2021",
        second: "",
        ends: "2024 (1)",
      },
      {
        index: "6",
        unit: "Marengo",
        name: "Annie Robertson",
        address:
          "60 Aspen Street, Gallion, AL 36742",
        phone: ["205-919-8081"],
        email: "robertsonannie1957@gmail.com",
        first: "2022",
        second: "",
        ends: "2025 (1)",
      },
      {
        index: "7",
        unit: "Butler (Vice Chair)",
        name: "Wayne Boswell",
        address:
          "124 Grant Street, Greenville, AL 36037",
        phone: ["334-382-5002"],
        email: "wayne.boswell@butlerco.k12.al.us",
        first: "2023",
        second: "",
        ends: "2026 (1)",
      },
      {
        index: "8",
        unit: "Mobile (Chair)",
        name: "Herbert White",
        address:
          "3920 Berwyn Drive, S, Apt. 214, Mobile, AL 36608",
        phone: ["251-454-4787"],
        email: "hkhwhite@bellsouth.net",
        first: "2021",
        second: "",
        ends: "2024(1)",
      },
      {
        index: "9",
        unit: "Dale",
        name: "Jo-Ellen Willis",
        address:
          "626 Highway 105, Ozark, AL 36360",
        phone: ["334-798-4834"],
        email: "jwillis626@troycable.net",
        first: "2019",
        second: "",
        ends: "2022 (1)",
      },
      {
        index: "10",
        unit: "Troy",
        name: "Vacant",
        address:
          "",
        phone: [],
        email: "",
        first: "2023",
        second: "",
        ends: "2026 (1)",
      },
      {
        index: "",
        unit: "Liaison",
        name: "Teresa Noell",
        subName: "AERA Parliamentarian",
        address: "2201 Hathaway Heights, Anniston, AL 36207",
        phone: ["256-225-5456"],
        email: "tnoellaera@gmail.com",
        first: "",
        second: "",
        ends: "",
      },
    ],
  },
  {
    title: "Legislative",
    data: [
      {
        index: "1",
        unit: "Limestone",
        name: "Elaine McCord",
        subName: "",
        address:
          "15854 Ham Road, Athens, AL 35611",
        phone: ["256-777-1399"],
        email: "ieemccord@gmail.com",
        first: "2023",
        second: "",
        ends: "2026 (1)",
      },
      {
        index: "1",
        unit: "Franklin",
        name: "Sandy Gibson",
        address:
          "1007 Spenser Way, Tuscumbia, AL 35674",
        phone: ["256-810-6917"],
        email: "chuckgib@bellsouth.net",
        first: "2018",
        second: "2021",
        ends: "2024 (2)",
      },
      {
        index: "2",
        unit: "Madison (Vice Chair)",
        name: "David Johnson",
        address:
          "133 Frank Church Road, Toney, AL 35773",
        phone: ["256-658-8433 "],
        email: "myaeanea@gmail.com",
        first: "2019",
        second: "2022",
        ends: "2025 (2)",
      },
      {
        index: "2",
        unit: "Marshall",
        name: "Stanley Mahan",
        address:
          "14 Johnson Drive, Albertville, AL 35950",
        phone: ["256-506-1975"],
        email: "sdmahan1@gmail.com",
        first: "2023",
        second: "",
        ends: "2026 (1)",
      },
      {
        index: "3",
        unit: "Sumter",
        name: "Bettye Maye",
        address:
          "1600 45th Avenue East, York, AL 36925",
        phone: ["205-499-0720"],
        email: "brm1600@att.net",
        first: "2018",
        second: "2021",
        ends: "2024 (2)",
      },
      {
        index: "4",
        unit: "Jefferson",
        name: "Jacqueline Oglesby",
        subName: "",
        address:
          "5381 Summerset Way, Bessemer, AL 35022",
        phone: ["205-230-3020"],
        email: "jtoglesby3@gmail.com",
        first: "2023",
        second: "",
        ends: "2026 (1)",
      },
      {
        index: "4",
        unit: "Jefferson",
        name: "Valaida Gentry",
        address:
          "629 Vaughn Circle, Birmingham, AL 35235",
        phone: ["205-915-1076"],
        email: "vgentry@yahoo.com",
        first: "2023",
        second: "",
        ends: "2026 (1)",
      },
      {
        index: "4",
        unit: "Jefferson (Chair)",
        name: "Margaret Beard",
        address:
          "1608 Pleasant Grove Road, Dolomite, AL 35115",
        phone: ["205-914-2650"],
        email: "mzbeard39@aol.com",
        first: "2019",
        second: "2022",
        ends: "2025 (2)",
      },
      {
        index: "4",
        unit: "Birmingham",
        name: "Dr. Rosalyn Childress",
        address:
          "625 Greenbriar Road, Fairfield, AL 35064",
        phone: ["205-746-1364"],
        email: "roschildress@aol.com",
        first: "2019",
        second: "2022",
        ends: "2025 (2)",
      },
      {
        index: "5",
        unit: "Lee",
        name: "Dwight E. Howard",
        address:
          "4702 Pebble Shore Drive, Opelika, AL 36804",
        phone: ["334-745-7293", "334-703-7041"],
        email: "dwighteh1@aol.com",
        first: "2020",
        second: "2023",
        ends: "2026 (2)",
      },
      {
        index: "5",
        unit: "Chilton",
        name: "Lucinda Champion Little",
        address:
          "339 Ballington Way, Clanton, AL 35405",
        phone: ["334-303-3511"],
        email: "llchampion1026@gmail.com",
        first: "2018",
        second: "2021",
        ends: "2024 (2)",
      },
      {
        index: "6",
        unit: "Dallas",
        name: "Jackie Walker",
        address:
          "974 Valleyview Drive, Valley Grande, AL 36703",
        phone: ["334-412-2831"],
        email: "jackiewalkerdst@yahoo.com",
        first: "2019",
        second: "2022",
        ends: "2025 (2)",
      },
      {
        index: "7",
        unit: "Lowndes",
        name: "Gwen Peterson",
        subName: "",
        address:
          "2136 Beverly Drive, Montgomery, AL 36111",
        phone: ["334-300-0636"],
        email: "gwnnovations@yahoo.com",
        first: "2020",
        second: "2023",
        ends: "2026 (2)",
      },
      {
        index: "7",
        unit: "Montgomery",
        name: "Zack Buckner",
        address:
          "201 Harvard Road, Montgomery, AL 36109",
        phone: [],
        email: "",
        first: "2021",
        second: "",
        ends: "2024 (1)",
      },
      {
        index: "8",
        unit: "Mobile",
        name: "Robert Davis",
        address:
          "4150 Weatherford Avenue, Mobile, AL 36609",
        phone: ["251-421-4009"],
        email: "robertearl1@comcast.net",
        first: "2022",
        second: "",
        ends: "2025 (1)",
      },
      {
        index: "8",
        unit: "Baldwin",
        name: "James Weekley",
        subName: "",
        address:
          "1101 Randall Avenue, Daphne, AL 36526",
        phone: ["251-455-1395"],
        email: "jweekley1956@att.net",
        first: "2020",
        second: "2023",
        ends: "2026 (2)",
      },
      {
        index: "8",
        unit: "Monroe (Secretary)",
        name: "Susan Boothe",
        address: "P.O. Box 471, Excel, AL 36439",
        phone: ["251-564-7190"],
        email: "sboothe_54@yahoo.com",
        first: "2021",
        second: "",
        ends: "2024 (1)",
      },
      {
        index: "9",
        unit: "Coffee",
        name: "Barbara Cole",
        address:
          "P.O. Box 471 New Brockton, AL 36351",
        phone: ["334-389-6335"],
        email: "colebarbarab@gmail.com",
        first: "2022",
        second: "",
        ends: "2025 (1)",
      },
      {
        index: "10",
        unit: "UABRA",
        name: "Leroy Cowan",
        subName: "",
        address:
          "705 Sherwood Road, Fairfield, AL 35064",
        phone: ["205-923-7003", "205-478-6110"],
        email: "Leroycowan86@yahoo.com",
        first: "2022",
        second: "",
        ends: "2025 (1)",
      },
      {
        index: "",
        unit: "Liaison",
        name: "Alma Wyatt Jones",
        subName:
          "AERA Member At Large Legislative",
        address:
          "1605 Montrose Drive South, Tuscaloosa, AL 35405",
        phone: ["205-345-5514", "205-242-4442"],
        email: "awj5514@aol.com",
        first: "",
        second: "",
        ends: "",
      },
    ],
  },
  {
    title: "Membership",
    data: [
      {
        index: "1",
        unit: "Marion",
        name: "Melinda Frix",
        address:
          "1103 Co Hwy 79, Phil Campbell, AL 35581",
        phone: ["205-269-2617"],
        email: "mhfrix49@gmail.com",
        first: "2023",
        second: "",
        ends: "2026 (1)",
      },
      {
        index: "2",
        unit: "Etowah",
        name: "Debbie Landers Martin",
        address:
          "65 Cottonwood Drive, Gadsden, AL 35901",
        phone: ["256-390-6185"],
        email: "debbielscott@bellsouth.net",
        first: "2018",
        second: "2021",
        ends: "2024 (2)",
      },
      {
        index: "3",
        unit: "Bibb (Vice Chair)",
        name: "Vickie Brown",
        address:
          "532 Ward Avenue, Brent, AL 35034",
        phone: ["205-316-8998"],
        email: "vgbrown06@gmail.com",
        first: "2022",
        second: "",
        ends: "2025 (1)",
      },
      {
        index: "4",
        unit: "Birmingham",
        name: "Dr. Lisa Stewart",
        address:
          "2278 Abbeyglen Circle, Hoover, AL 35226",
        phone: ["205-266-8092"],
        email: "stewartbham1@gmail.com",
        first: "2023",
        second: "",
        ends: "2026 (1)",
      },
      {
        index: "5",
        unit: "Tallapoosa (Secretary)",
        name: "Mary Warren",
        address:
          "170 Royston Street, Dadeville, AL 36853",
        phone: ["706-570-3503"],
        email: "cas3suns@bellsouth.net",
        first: "2018",
        second: "2021",
        ends: "2024 (2)",
      },
      {
        index: "6",
        unit: "Wilcox (Chair)",
        name: "Marvin Carter",
        address: "P.O. Box 362 Camden, AL 36726",
        phone: ["334-303-4785"],
        email: "mecarter@frontiernet.net",
        first: "2022",
        second: "",
        ends: "2025 (1)",
      },
      {
        index: "7",
        unit: "Montgomery",
        name: "Dorothy G. Henry",
        address:
          "PO Box 201117, Montgomery, AL 36120",
        phone: ["334-701-3332"],
        email: "dghenry7005@att.net",
        first: "2023",
        second: "",
        ends: "2026 (1)",
      },
      {
        index: "8",
        unit: "Mobile",
        name: "Edna Douglas Davis",
        address:
          "4150 Weatherford Avenue, Mobile, AL 36609",
        phone: ["251-604-4267"],
        email: "ecdouglas1@aol.com",
        first: "2021",
        second: "",
        ends: "2024 (1)",
      },
      {
        index: "9",
        unit: "Dale",
        name: "Beverly Jones Lampkin",
        address:
          "115 Montrose Ct., Apt. 168, Dothan, AL 36305",
        phone: ["334-803-1691"],
        email: "lampkinbeverly45@gmail.com",
        first: "2022",
        second: "",
        ends: "2025 (1)",
      },
      {
        index: "10",
        unit: "TUARA",
        name: "Debbie Lane",
        address: "709 Cambridge Lane, Tuscaloosa, AL 35406",
        phone: ["205-331-3341"],
        email: "Lanedeborah201@gmail.com",
        first: "2023",
        second: "",
        ends: "2026 (1)",
      },
      {
        index: "",
        unit: "Liaison",
        name: "Vivian Crosby",
        subName:
          "AERA Member at Large, Membership",
        address:
          "7042 Berryhill Place, Apt. C, Montgomery, AL 36117",
        phone: ["334-309-6209"],
        email: "Vivian.crosby@att.net",
        first: "",
        second: "",
        ends: "",
      },
    ],
  },
  {
    title: "Nomination & Elections",
    data: [
      {
        index: "1",
        unit: "Limestone",
        name: "Tressie McLemore",
        address:
          "27307 Cedar Hill Road, Ardmore 35739",
        phone: ["256-423-8844", "256-777-7576"],
        email: "tressiemclemore@yahoo.com",
        first: "2020",
        second: "2023",
        ends: "2026 (2)",
      },
      {
        index: "2",
        unit: "Etowah",
        name: "Donna Eaves",

        address:
          "830 Cynthia Street, Oxford, AL 36203",
        phone: ["256-831-1220"],
        email: "deaves1036@aol.com",
        first: "2021",
        second: "",
        ends: "2024 (1)",
      },
      {
        index: "3",
        unit: "Fayette (Secretary)",
        name: "Carol Abernathy",
        subName: "",
        address:
          "592 Abernathy Road, Fayette, AL 35555",
        phone: ["205-932-6599", "205-270-9993"],
        email: "abernathy437@centurytel.net",
        first: "2019",
        second: "2022",
        ends: "2025 (2)",
      },
      {
        index: "4",
        unit: "Blount",
        name: "John Graves",
        subName: "",
        address:
          "7571 Joy Road, Blountsville, AL 35031",
        phone: ["205-429-3161"],
        email: "junglegems2@yahoo.com",
        first: "2020",
        second: "2023",
        ends: "2026 (2)",
      },
      {
        index: "5",
        unit: "Chambers",
        name: "Shirley Aaron",
        address:
          "609 Avenue E, West Point, GA 31833",
        phone: ["706-585-2618"],
        email: "saaron126@knology.net",
        first: "2018",
        second: "2021",
        ends: "2024 (2)",
      },
      {
        index: "6",
        unit: "Choctaw",
        name: "Joy Seybold",
        subName: "",
        address: "616 Sund, Butler, AL 36904",
        phone: ["205-459-3783"],
        email: "jseybold@tds.net",
        first: "2019",
        second: "2022",
        ends: "2025 (2)",
      },
      {
        index: "7",
        unit: "Russell (Chair)",
        name: "Dr. Joyce Harris-Stokes",
        address:
          "46 Harris Road, Seale, AL 36875",
        phone: ["706-580-6896", "334-329-9734"],
        email: "jharrisstokes@gmail.com",
        first: "2020",
        second: "2023",
        ends: "2026 (2)",
      },
      {
        index: "8",
        unit: "No. Mobile",
        name: "Douglas July",
        address: "4463 Highpoint Blvd., Eight Mile, AL 36613",
        phone: ["251-401-0863"],
        email: "dljulysr@yahoo.com",
        first: "2018",
        second: "2021",
        ends: "2024 (2)",
      },
      {
        index: "9",
        unit: "Covington",
        name: "Margaret Sanders",
        address:
          "P.O. Box 1016, Andalusia, AL 36420",
        phone: ["334-222-5382", "334-488-3529"],
        email: "Sandersmg1951@gmail.com",
        first: "2022",
        second: "",
        ends: "2025 (1)",
      },
      {
        index: "10",
        unit: "AURA (Vice Chair)",
        name: "Dr. Tom Vocino",
        address:
          "P.O. Box 640296, Pike Road, AL 36064",
        phone: ["334-277-4619"],
        email: "tvocino1@gmail.com",
        first: "2020",
        second: "2023",
        ends: "2026 (2)",
      },
      {
        index: "",
        unit: "Liaison",
        name: "Rex Cheatham",
        subName: "AERA Vice President",
        address:
          "1904 Resnick Drive, Decatur, AL 35603",
        phone: ["256-565-2027"],
        email: "rex.rexcheatham@yahoo.com",
        first: "",
        second: "",
        ends: "",
      },
    ],
  },
];

const StateCommitteesContainer = styled.div``;

const BODImg = styled.img`
  margin: 54px auto;
  max-width: 100%;
`;

const TabItem = styled.div<{
  isActive: boolean;
}>`
  ${({ isActive }) => `
    box-shadow: inset 0px -${
      isActive ? 6 : 0
    }px 0px ${DS.colors.magenta};
    &:hover {
    box-shadow: inset 0px -${
      isActive ? 6 : 2
    }px 0px ${DS.colors.magenta};
    }
  `}
  margin-right: 50px;
  padding-bottom: 17px;
  cursor: pointer;
  transition: all 0.3s ease;
`;

const Tab = styled.div`
  display: flex;
`;

const TabScroll = styled.div`
  pointer-events: none;
  position: absolute;
  right: -1px;
  top: 0px;
  bottom: 0px;
  width: 30px;
  box-shadow: 0px 0px 30px 30px rgb(255 255 255);
`;

const StateCommittees = () => {
  const [activeIndex, setIndex] = useState(0);
  const ref = useRef<ScrollContainer>(null);
  const onClick = useCallback(
    (index: number) => {
      setIndex(index);
    },
    [setIndex]
  );
  const scrollTo = useCallback(
    (div: HTMLDivElement) => {
      ref.current?.getElement().scrollTo({
        left: div.offsetLeft,
        behavior: "smooth",
      });
    },
    []
  );

  return (
    <StateCommitteesContainer>
      <Wrapper
        type="m"
        display="flex"
        alignItems="center"
        flexDirection="column"
      >
        <H2
          style={{
            textAlign: "center",
            maxWidth: 936,
          }}
        >
          Alabama Education Retirees Association
          District Map
        </H2>
        <BODImg
          src={bod}
          alt="board of director"
        />
      </Wrapper>
      <Wrapper
        type="m"
        display="flex"
        alignItems="center"
        flexDirection="column"
        marginTop="30px"
        marginBottom="67px"
      >
        <H2
          style={{
            textAlign: "center",
            maxWidth: 936,
          }}
        >
          2023-2024 State Committee
        </H2>
      </Wrapper>
      <Wrapper
        type="m"
        style={{
          position: "relative",
        }}
      >
        <ScrollContainer
          ref={ref}
          style={{
            position: "relative",
          }}
        >
          <Tab>
            {data.map(({ title }, index) => {
              return (
                <TabItem
                  key={index}
                  isActive={index === activeIndex}
                  onClick={(e) => {
                    onClick(index);
                    console.log(e.currentTarget);
                    scrollTo(e.currentTarget);
                  }}
                >
                  <SubTitle2
                    style={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    {title}
                  </SubTitle2>
                </TabItem>
              );
            })}
            <div
              style={{
                width: 1,
                height: 1,
                flexShrink: 0,
              }}
            ></div>
          </Tab>
        </ScrollContainer>
        <TabScroll />
      </Wrapper>
      <Wrapper type="m" marginTop="50px">
        <Table
          spacing={"2%"}
          mobileCols={["20%", "80%"]}
          cols={[
            "10%",
            "30%",
            "36%",
            "8%",
            "8%",
            "8%",
          ]}
          headers={[
            <Body1 fontColor="white">
              District
            </Body1>,
            <Body1 fontColor="white">
              Local Unit Office
            </Body1>,
            <Body1 fontColor="white">
              Name and Contact Information
            </Body1>,
            <Body1 fontColor="white">1st</Body1>,
            <Body1 fontColor="white">2nd</Body1>,
            <Body1 fontColor="white">Ends</Body1>,
          ]}
          mobileHeaders={[
            <Body1>District</Body1>,
            <Body1>Local Unit Office</Body1>,
            <Body1>
              Name and Contact Information
            </Body1>,
            <Body1>1st</Body1>,
            <Body1>2nd</Body1>,
            <Body1>Ends</Body1>,
          ]}
          data={data[activeIndex].data.map(
            (row) => {
              return [
                <Body1>{row.index}</Body1>,
                <Body1>{row.unit}</Body1>,
                <>
                  <H4>{row.name}</H4>
                  {row.subName ? (
                    <Body1>{row.subName}</Body1>
                  ) : null}
                  {row.phone.length || row.email.length || row.address.length ? (
                    <LinkContainer>
                    { row.phone.length ? (
                      <div>
                        <img
                          src={mobile}
                          alt={"phone"}
                        />
                        <Body1>
                          {row.phone.map(
                            (_phone, index) => {
                              return (
                                <CustomLink
                                  to={{
                                    pathname: `tel:${_phone}`,
                                  }}
                                  key={index}
                                  target="_top"
                                >
                                  {_phone}
                                  {index + 1 <
                                  row.phone.length
                                    ? ", "
                                    : ""}
                                </CustomLink>
                              );
                            }
                          )}
                        </Body1>
                      </div>) : null }
                      { row.email.length ? (
                        <Link
                          to={{
                            pathname: `mailto:${row.email}`,
                          }}
                          target="_top"
                        >
                          <img
                            src={mail}
                            alt={"mail"}
                          />
                          <Body1
                            style={{
                              overflowWrap:
                                "anywhere",
                            }}
                          >
                            {row.email}
                          </Body1>
                        </Link>
                      ) : null}
                      { row.address.length ? (
                      <div>
                        <img
                          src={navigation}
                          alt={"map"}
                        />
                        <Body1>
                          {row.address}
                        </Body1>
                      </div>): null }
                    </LinkContainer>
                  ) : null}
                </>,
                <Body1>{row.first}</Body1>,
                <Body1>{row.second}</Body1>,
                <Body1>{row.ends}</Body1>,
              ];
            }
          )}
        />
      </Wrapper>
      {/* <LightDivider marginTop="60px" /> */}

      <Wrapper
        type="m"
        marginTop="60px"
      ></Wrapper>
      <EmailSubscribe />
      <Wrapper
        type="m"
        marginTop="50px"
      ></Wrapper>
      <MarkImg src={mark} alt="AERA" />
    </StateCommitteesContainer>
  );
};

export default StateCommittees;
