import { useScreenClass } from "@tinyd/usescreenclass";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import DS from "../../DS/DS";
import Body1 from "../../DS/Typo/Body1";
import mobile from "../../assets/icons/mobile_dark_red.svg";
const RLinkCardContainer = styled(Link)`
  background-color: ${DS.colors.white};
  overflow: hidden;
  box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.16),
    inset 0 3px 0px ${DS.colors.magenta};
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.3s ease;
  &:hover {
    box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.2),
      inset 0 8px 0px ${DS.colors.magenta};
  }
  & > p {
    width: calc(100% - 50px);
  }
`;

const RLinkCardIconContainer = styled.div`
  height: 155px;
  display: flex;
  justify-content: center;
  align-items: center;

  & > img {
    flex: 1;
    object-fit: scale-down;
    max-width: calc(80% - 20px);
  }
`;

const RLinkCard: FC<{
  icon: string;
  text: string;
  link: string;
  phone?: string;
}> = ({ icon, text, link, phone = "" }) => {
  const screen = useScreenClass();
  const isSmall = ["xs", "sm"].includes(screen);
  return (
    <RLinkCardContainer
      to={{ pathname: link }}
      target="_blank"
      style={
        isSmall
          ? {
              width: "calc(100% / 1 - 15px) ",
              marginBottom: 30,
              height: 160,
            }
          : {
              width:
                "calc(100% / 3 - (56px / 2))",
              marginBottom: 55,
              height: 230,
            }
      }
    >
      <RLinkCardIconContainer
        style={
          isSmall
            ? {
                height: 100 - 20,
              }
            : {
                height: 155 - 20,
              }
        }
      >
        <img
          src={icon}
          alt={text}
          style={
            isSmall
              ? {
                  height: 80 - 20,
                }
              : {
                  height: 135 - 20,
                }
          }
        />
      </RLinkCardIconContainer>
      <Body1
        style={{
          textAlign: "center",
        }}
      >
        {text}
      </Body1>
      {phone.length ? (
        <div
          // to={{
          //   pathname: `tel:${phone}`,
          // }}

          // target="_parent"
          style={{
            display: "flex",
            width: "calc(100% - 20px)",
            justifyContent: "center",
            marginTop: 10,
          }}
        >
          <img
            src={mobile}
            alt={phone}
            style={{
              position: "relative",
              marginRight: 10,
              top: -2,
            }}
          />
          <Body1
            style={{
              textAlign: "center",
            }}
          >
            {phone}
          </Body1>
        </div>
      ) : null}
    </RLinkCardContainer>
  );
};

export default RLinkCard;
