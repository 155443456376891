import React, {
  FC,
  useEffect,
  useState,
} from "react";
import styled from "styled-components";
import DS from "../../DS/DS";
import Input from "../../DS/Input/Input";
import Body1 from "../../DS/Typo/Body1";
import Caption from "../../DS/Typo/Caption";
import Wrapper from "../../DS/Wrapper/Wrapper";
import getAllNews, {
  NewsType,
} from "../../services/getAllNews";
import EmailSubscribe from "../EmailSubscribe/EmailSubscribe";
import LightDivider from "../LightDivider/LightDivider";
import NewsCard from "../NewsCard/NewsCard";
import TriColorDivider from "../TriColorDivider/TriColorDivider";
import search from "../../assets/icons/search.svg";
import dayjs from "dayjs";
import {
  Route,
  Switch,
  useParams,
} from "react-router";
import Button from "../../DS/Button/Button";
import { Link } from "react-router-dom";
import getNews, {
  SingleNewsType,
} from "../../services/getNews";
import { useSetSharedState } from "../../hooks/useSharedState/useSharedState";
import heroBG from "../../assets/images/news_bg.jpg";
import NewsDetails from "../NewsDetails/NewsDetails";
import mark from "../../assets/images/mark.png";
const MarkImg = styled.img`
  position: absolute;
  bottom: -50%;
  right: 0;
  width: 110px;
`;
const CategoryContainer = styled.div`
  height: 40px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${DS.colors.lightGray1};
  padding: 0px 20px;
  transition: all 0.3s ease;
`;

const Category: FC<{
  text: string;
  onClick: () => any;
  isActive: boolean;
}> = ({ text, onClick, isActive }) => {
  return (
    <CategoryContainer
      onClick={onClick}
      style={{
        backgroundColor: isActive
          ? DS.colors.yellow
          : DS.colors.lightGray1,
      }}
    >
      <Caption>{text}</Caption>
    </CategoryContainer>
  );
};

const CategoriesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  & > div {
    margin-right: 20px;
  }
`;

const NewsContainer = styled.div``;
const NewsItemsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  & > * {
    margin-bottom: 30px;
  }
`;

const CustomInputContainer = styled.div`
  display: flex;
  border-bottom: 1px solid #707070;
`;

const CustomInput = styled(Input)`
  border: none;
  flex: 1;
  padding-left: 0px;
`;

const NewsHome = () => {
  const [loading, setLoading] = useState(true);
  const [news, setNews] = useState<NewsType[]>(
    []
  );
  const [filteredNews, setFilteredNews] =
    useState<NewsType[]>([]);
  const [categories, setCategories] = useState<
    string[]
  >([]);
  const [filter, setFilter] = useState<{
    type: "cat" | "text";
    value: string;
  }>({ type: "text", value: "" });
  const setTitle =
    useSetSharedState("news_title");
  const setBanner = useSetSharedState(
    "news_banner"
  );
  const setDate = useSetSharedState("news_date");
  useEffect(() => {
    setTitle("Newsroom");
    setBanner(heroBG);
    setDate("");
  }, [setBanner, setTitle, setDate]);
  useEffect(() => {
    getAllNews()
      .then((news) => {
        setNews(news);
        setFilteredNews(news);
        let cats: string[] = [];
        news.forEach((d) => {
          if (!cats.includes(d.acf.category.name))
            cats.push(d.acf.category.name);
        });

        setCategories(cats);
        setFilter({ type: "text", value: "" });
        setLoading(false);
      })
      .catch((error) => console.error(error));
  }, [setNews]);
  useEffect(() => {
    if (news.length) {
      switch (filter.type) {
        case "cat":
          setFilteredNews(
            news.filter(
              (d) =>
                d.acf.category.name ===
                filter.value
            )
          );
          break;
        default:
          setFilteredNews(
            news.filter((d) =>
              d.title.rendered
                .toLocaleLowerCase()
                .includes(
                  filter.value.toLocaleLowerCase()
                )
            )
          );
      }
    }
  }, [filter, news, setFilteredNews]);
  return (
    <NewsContainer>
      <TriColorDivider loading={loading} />
      <Wrapper type="m" marginTop="60px">
        <CustomInputContainer>
          <CustomInput
            placeholder="Type your search here"
            onChange={(e) => {
              setFilter({
                type: "text",
                value: e.target.value || "",
              });
            }}
          />
          <img src={search} alt="Search" />
        </CustomInputContainer>
      </Wrapper>
      <Wrapper type="l" marginTop="60px">
        <CategoriesContainer>
          {categories.map((category, index) => {
            return (
              <Category
                text={category}
                key={index}
                isActive={
                  category === filter.value
                }
                onClick={() => {
                  if (
                    filter.type === "cat" &&
                    category === filter.value
                  ) {
                    setFilter({
                      type: "text",
                      value: "",
                    });
                  } else {
                    setFilter({
                      type: "cat",
                      value: category,
                    });
                  }
                }}
              />
            );
          })}
        </CategoriesContainer>
      </Wrapper>
      <Wrapper type="l" marginTop="60px">
        <NewsItemsContainer>
          {filteredNews.map(
            ({ title, acf, slug }, index) => {
              const long =
                (index + 1) % 4 === 1 ||
                (index + 1) % 4 === 2
                  ? true
                  : false;
              return (
                <NewsCard
                  title={title.rendered}
                  category={acf.category.name}
                  img={acf.news_banner.url}
                  link={`/news/${slug}`}
                  key={index}
                  long={
                    index + 1 > 3 ? long : false
                  }
                  singleOnMobile={true}
                />
              );
            }
          )}
        </NewsItemsContainer>
        {filteredNews.length === 0 && !loading ? (
          <Body1>No News Found</Body1>
        ) : null}
      </Wrapper>
      <LightDivider marginTop="100px" type="l" />
      <EmailSubscribe />
      <Wrapper type="m" marginTop="80px" />
      <MarkImg src={mark} alt="AERA" />
    </NewsContainer>
  );
};

const News404 = () => {
  return (
    <>
      <Wrapper type="m" marginTop="100px">
        <Body1
          style={{
            textAlign: "center",
          }}
        >
          Sorry! Looks like link you have visited
          is broken
        </Body1>
      </Wrapper>
      <Wrapper
        type="m"
        marginTop="30px"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Link to="/news">
          <Button>Go Back to News</Button>
        </Link>
      </Wrapper>
      <EmailSubscribe />
      <Wrapper type="m" marginTop="80px" />
    </>
  );
};

const NewsDetailPage: FC = () => {
  const [loading, setLoading] = useState(true);
  const { id } = useParams<{ id: string }>();
  const [news, setNews] =
    useState<SingleNewsType>();
  const setTitle =
    useSetSharedState("news_title");
  const setBanner = useSetSharedState(
    "news_banner"
  );
  const setDate = useSetSharedState("news_date");
  useEffect(() => {
    if (!news)
      getNews(id).then((news) => {
        setNews(news);
        setLoading(false);
        setTitle(
          news.title.rendered.replace(
            "&#8230;",
            "..."
          )
        );
        setBanner(news.acf.news_banner.url);
        setDate(
          dayjs(news.date).format("MMMM D, YYYY")
        );
      });
  }, [id, setTitle, news, setBanner, setDate]);
  return (
    <NewsContainer>
      <TriColorDivider loading={loading} />
      <Wrapper type="m" marginTop="60px">
        {!loading && news ? (
          <NewsDetails data={news} />
        ) : null}
      </Wrapper>
      <LightDivider marginTop="100px" type="l" />
      <EmailSubscribe />
      <Wrapper type="m" marginTop="80px" />
    </NewsContainer>
  );
};

const News: FC = () => {
  return (
    <Switch>
      <Route path="/news" exact>
        <NewsHome />
      </Route>
      <Route path="/news/:id" exact>
        <NewsDetailPage />
      </Route>
      <Route>
        <News404 />
      </Route>
    </Switch>
  );
};

export default News;
