import React, {
  FC,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Route, Switch } from "react-router";
import styled from "styled-components";
import DS from "../../DS/DS";
import { getHeaderHeight } from "../Header/Header";
import Hero404 from "../Hero404/Hero404";
import HeroAbout from "../HeroAbout/HeroAbout";
import HeroAdvocacy from "../HeroAdvocacy/HeroAdvocacy";
import HeroClickAndSave from "../HeroClickAndSave/HeroClickAndSave";
import HeroContact from "../HeroContact/HeroContact";
import HeroDocuments from "../HeroDocuments/HeroDocuments";
import HeroHelpful from "../HeroHelpful/HeroHelpful";
import HeroHome from "../HeroHome/HeroHome";
import HeroJoinAERA from "../HeroJoinAERA/HeroJoinAERA";
import HeroNews from "../HeroNews/HeroNews";
import HeroScholarships from "../HeroScholarships/HeroScholarships";
import HeroJobs from "../HeroJobs/HeroJobs";

const HeroContainerWrapper = styled.div`
  transition: all 0.3s cubic-bezier(0.51, 0.12, 0.23, 0.86);
  background-color: ${DS.colors.darkPurple};
  overflow: hidden;
`;
const HeroContainer = styled.div`
  transition: all 0.3s cubic-bezier(0.51, 0.12, 0.23, 0.86);
  background-color: ${DS.colors.darkPurple};
  overflow: hidden;
`;

const HeightAnimator: FC<{
  height: number | string;
  show: boolean;
  setHeight?: (
    height: number | string,
    minHeight?: number | string
  ) => any;
  minHeight?: number | string;
}> = ({
  children,
  height,
  setHeight = () => {},
  show,
  minHeight,
}) => {
  useEffect(() => {
    setHeight(height, minHeight);
  }, [height, setHeight, minHeight]);
  if (show) return <>{children}</>;

  return null;
};

const Hero = () => {
  const [height, setHeight] = useState<number | string>(
    "0px"
  );
  const [heightWrapper, setHeightWrapper] = useState<
    number | string
  >(1000);
  const [minHeight, setMinHeight] = useState<
    number | string
  >("unset");
  const [minHeightWrapper, setMinHeightWrapper] = useState<
    number | string
  >("unset");
  const [show, setShow] = useState(false);
  const onHeightChanged = useCallback(
    (
      height: number | string,
      minHeight: number | string = "unset"
    ) => {
      console.log(
        typeof height === "number" ? `${height}px` : height
      );
      setShow(false);
      setHeight("0px");
      setMinHeight("unset");
      setHeightWrapper(height);
      setMinHeightWrapper(minHeight);
      setTimeout(() => {
        setHeight(height);
        setMinHeight(minHeight);
        setShow(true);
      }, 300);
    },
    [setHeight]
  );
  return (
    <HeroContainerWrapper
      style={{
        height: heightWrapper,
        minHeight: minHeightWrapper,
      }}
    >
      <HeroContainer
        style={{
          height,
          minHeight,
          opacity: height === "0px" ? 0 : 1,
        }}
      >
        <Switch>
          <Route path="/" exact>
            <HeightAnimator
              height={650}
              show={show}
              setHeight={onHeightChanged}
            >
              <HeroHome />
            </HeightAnimator>
          </Route>
          <Route path="/join" exact>
            <HeightAnimator
              show={show}
              height={`calc(100vh - ${
                getHeaderHeight() + 48
              }px)`}
              setHeight={onHeightChanged}
              minHeight={"700px"}
            >
              <HeroJoinAERA />
            </HeightAnimator>
          </Route>
          <Route path="/advocacy" exact>
            <HeightAnimator
              show={show}
              height={"492px"}
              setHeight={onHeightChanged}
            >
              <HeroAdvocacy />
            </HeightAnimator>
          </Route>
          <Route path="/scholarships" exact>
            <HeightAnimator
              show={show}
              height={"492px"}
              setHeight={onHeightChanged}
            >
              <HeroScholarships />
            </HeightAnimator>
          </Route>
          <Route path="/contact" exact>
            <HeightAnimator
              show={show}
              height={"492px"}
              setHeight={onHeightChanged}
            >
              <HeroContact />
            </HeightAnimator>
          </Route>
          <Route path="/helpful" exact>
            <HeightAnimator
              show={show}
              height={"492px"}
              setHeight={onHeightChanged}
            >
              <HeroHelpful />
            </HeightAnimator>
          </Route>
          <Route path="/click_and_save" exact>
            <HeightAnimator
              show={show}
              height={"492px"}
              setHeight={onHeightChanged}
            >
              <HeroClickAndSave />
            </HeightAnimator>
          </Route>
          <Route path="/about">
            <HeightAnimator
              show={show}
              height={"492px"}
              setHeight={onHeightChanged}
            >
              <HeroAbout />
            </HeightAnimator>
          </Route>
          <Route path="/documents">
            <HeightAnimator
              show={show}
              height={"492px"}
              setHeight={onHeightChanged}
            >
              <HeroDocuments />
            </HeightAnimator>
          </Route>
          <Route path="/news">
            <HeightAnimator
              show={show}
              height={"492px"}
              setHeight={onHeightChanged}
            >
              <HeroNews />
            </HeightAnimator>
          </Route>
          <Route path="/job-openings">
            <HeightAnimator
              show={show}
              height={"492px"}
              setHeight={onHeightChanged}
            >
              <HeroJobs />
            </HeightAnimator>
          </Route>
          <Route>
            <HeightAnimator
              show={show}
              height={`calc(100vh - ${getHeaderHeight()}px)`}
              setHeight={onHeightChanged}
            >
              <Hero404 />
            </HeightAnimator>
          </Route>
        </Switch>
      </HeroContainer>
    </HeroContainerWrapper>
  );
};

export default Hero;
