import styled from "styled-components";
import DS, { DSColorType } from "../DS";
import { Body1CSS } from "../Typo/Body1";
import { Property } from "csstype";
import { BaseTypoProps } from "../Typo/BaseTypo";
export type ButtonProps = {
  bgColor?: keyof DSColorType;
  color?: keyof DSColorType;
  hoverColor?: keyof DSColorType;
  height?: Property.Height;
  minWidth?: Property.MinWidth;
  shadow?: boolean;
} & BaseTypoProps;

const Button = styled.button<ButtonProps>`
  ${({
    paddingLeft = "32px",
    paddingRight = "32px",
  }) => Body1CSS({ paddingLeft, paddingRight })};
  ${({
    bgColor = "magenta",
    color = "white",
    hoverColor = "darkPurple",
    height = "51px",
    minWidth = "160px",
    shadow = false,
  }) => `
    background-color: ${DS.colors[bgColor]};
    box-shadow: inset 0px 0px 0px 2px ${
      DS.colors[bgColor]
    }${
    shadow
      ? ",0px 0px 10px rgba(0, 0, 0, 0.2);"
      : ""
  };
    color: ${DS.colors[color]};
    height:${height};
    min-width: ${minWidth};
    &:hover {
    color: ${DS.colors[hoverColor]};
    background-color: ${DS.colors.white};
    
    }
  `}
  border:none;
  font-weight: 900;
  transition: all 0.3s ease;
  cursor: pointer;
  outline: none;
  line-height: 0px !important;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default Button;
