import React, { FC } from "react";
import styled from "styled-components";
import DS from "../../DS/DS";
import Wrapper, {
  WrapperProps,
} from "../../DS/Wrapper/Wrapper";

const LightDividerLine = styled.div`
  background-color: ${DS.colors.dimGray};
  height: 1px;
`;

const LightDivider: FC<WrapperProps> = (
  props
) => {
  return (
    <Wrapper {...props}>
      <LightDividerLine />
    </Wrapper>
  );
};

export default LightDivider;
