import React from "react";
import { Parallax } from "react-parallax";
import styled from "styled-components";
import heroBG from "../../assets/images/home_hero.jpg";
import DS from "../../DS/DS";
import Color from "color";
import {
  Slide,
  Fade,
} from "react-awesome-reveal";
import H1 from "../../DS/Typo/H1";
import Wrapper from "../../DS/Wrapper/Wrapper";
import Body1 from "../../DS/Typo/Body1";
const HeroHomeContainer = styled.div`
  height: 650px;
  background: linear-gradient(
    90deg,
    ${Color(DS.colors.darkPurple)
        .rgb()
        .alpha(0.99)
        .toString()}
      50%,
    ${Color(DS.colors.darkPurple)
        .rgb()
        .alpha(0)
        .toString()}
      100%
  );
  display: flex;
  align-items: center;
`;
const HeroHome = () => {
  return (
    <Parallax bgImage={heroBG} strength={-100}>
      <HeroHomeContainer>
        <Wrapper type="m">
          <Slide
            direction="up"
            triggerOnce
            cascade
            duration={500}
          >
            <Fade triggerOnce>
              <H1
                fontColor="white"
                marginBottom="5px"
                style={{ maxWidth: 696 }}
              >
                Fighting to Protect Your Hard
                Earned Benefits!
              </H1>
            </Fade>
            <Fade delay={500} triggerOnce>
              <Body1
                fontColor="white"
                style={{ maxWidth: 620 }}
              >
                Membership makes us stronger
                especially at the Alabama
                Legislature where numbers speak
                louder than words. The value of
                advocating on behalf of retirees
                is immeasurable.
              </Body1>
            </Fade>
          </Slide>
        </Wrapper>
      </HeroHomeContainer>
    </Parallax>
  );
};

export default HeroHome;
