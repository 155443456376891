import React, { FC } from "react";
import { SingleNewsType } from "../../services/getNews";
import parse, {
  HTMLReactParserOptions,
  domToReact,
} from "html-react-parser";
import Body1 from "../../DS/Typo/Body1";
import { Element } from "domhandler";
import H1 from "../../DS/Typo/H1";
import H2 from "../../DS/Typo/H2";
import H3 from "../../DS/Typo/H3";
import H4 from "../../DS/Typo/H4";
import SubTitle1 from "../../DS/Typo/SubTitle1";
import SubTitle2 from "../../DS/Typo/SubTitle2";
import { Link } from "react-router-dom";
import styled from "styled-components";
import mark from "../../assets/images/mark.png";
import { useScreenClass } from "@tinyd/usescreenclass";
import DS from "../../DS/DS";

const StyledDiv = styled.div`
  ${() => {
    let colorClass = Object.entries(
      DS.colors
    ).map(([color, value]) => {
      return `.color-${color}{
        color:${value};
      }`;
    });
    console.log(colorClass);
    return colorClass.join("\n");
  }}
`;

const TableContainer = styled.table`
  width: 100%;
  background: #f8f8f8;
  & tr {
    display: flex;
  }
  & td {
    padding: 20px;
    flex: 1;
    border: 1px solid ${DS.colors.lightGray};
  }
`;

const MarkImg = styled.img`
  position: absolute;
  bottom: 15%;
  right: 0;
  width: 110px;
`;
const ImageBlockContainer = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  & > img {
    max-width: 320px;
  }
`;

const ImageBlock: FC<{ src: string }> = ({
  src,
}) => {
  const screen = useScreenClass();
  const isSmall = ["sm", "xs"].includes(screen);
  return (
    <ImageBlockContainer>
      <img
        src={src}
        alt="blog"
        style={{
          maxWidth: isSmall ? "100%" : "500px",
        }}
      />
    </ImageBlockContainer>
  );
};

const htmlOptions: HTMLReactParserOptions = {
  replace: (domNode) => {
    console.log(
      domNode,
      domNode instanceof Element
    );

    if ((domNode as Element).tagName) {
      // console.log(domNode);
      if ((domNode as Element).tagName)
        switch ((domNode as Element).tagName) {
          case "p":
            return (
              <Body1 marginBottom="20px">
                {domToReact(
                  (domNode as Element).children,
                  htmlOptions
                )}
              </Body1>
            );
          case "li":
            return (
              <Body1 as="li">
                {domToReact(
                  (domNode as Element).children,
                  htmlOptions
                )}
              </Body1>
            );
          case "a":
            console.log(domNode);
            return (
              <Link
                to={{
                  pathname: (domNode as Element)
                    .attribs.href,
                }}
                target="_blank"
              >
                <Body1
                  as="span"
                  fontColor="magenta"
                >
                  {domToReact(
                    (domNode as Element).children,
                    htmlOptions
                  )}
                </Body1>
              </Link>
            );
          case "h1":
            return (
              <H1
                marginTop="20px"
                marginBottom="20px"
              >
                {domToReact(
                  (domNode as Element).children,
                  htmlOptions
                )}
              </H1>
            );
          case "h2":
            return (
              <H2
                marginTop="20px"
                marginBottom="20px"
              >
                {domToReact(
                  (domNode as Element).children,
                  htmlOptions
                )}
              </H2>
            );
          case "h3":
            return (
              <H3
                marginTop="15px"
                marginBottom="15px"
                fontFamily="NeueHaasUnica"
              >
                {domToReact(
                  (domNode as Element).children,
                  htmlOptions
                )}
              </H3>
            );
          case "h4":
            return (
              <H4
                marginTop="10px"
                marginBottom="10px"
              >
                {domToReact(
                  (domNode as Element).children,
                  htmlOptions
                )}
              </H4>
            );
          case "h5":
            return (
              <SubTitle1
                marginTop="10px"
                marginBottom="10px"
              >
                {domToReact(
                  (domNode as Element).children,
                  htmlOptions
                )}
              </SubTitle1>
            );
          case "h6":
            return (
              <SubTitle2
                marginTop="10px"
                marginBottom="10px"
              >
                {domToReact(
                  (domNode as Element).children,
                  htmlOptions
                )}
              </SubTitle2>
            );
          case "img":
            console.log(domNode);
            return (
              <ImageBlock
                src={
                  (domNode as Element).attribs.src
                }
              />
            );
          case "table":
            console.log(domNode);
            return (
              <TableContainer>
                {domToReact(
                  (domNode as Element).children,
                  htmlOptions
                )}
              </TableContainer>
            );
          default:
            return null;
        }
    }
  },
};

const NewsDetails: FC<{ data: SingleNewsType }> =
  ({ data }) => {
    return (
      <StyledDiv>
        {parse(
          data.acf.news_content,
          htmlOptions
        )}
        <MarkImg src={mark} alt="AERA" />
      </StyledDiv>
    );
  };

export default NewsDetails;
