import { useScreenClass } from "@tinyd/usescreenclass";
import React, { FC } from "react";
import styled from "styled-components";
import H4 from "../../DS/Typo/H4";
import Wrapper from "../../DS/Wrapper/Wrapper";
import bod from "../../assets/icons/bod.svg";
import af from "../../assets/icons/af.svg";
import ac from "../../assets/icons/sc.svg";
import lu from "../../assets/icons/lu.svg";
import { Link } from "react-router-dom";

const CTAWithIconContainer = styled(Link)`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transition: all 0.3s ease;
  transform: scale(1);
  & > div > h4 {
    flex: 0;
  }
  & > div > div {
    display: flex;
    margin-bottom: 11px;
  }
  & > div {
    display: flex;
    flex-direction: column;
  }
  &:hover {
    transform: scale(1.05);
  }
`;

const CTAWithIcon: FC<{
  text: string;
  icon: string;
  url: string;
  align?: string;
}> = ({ text, icon, url, align }) => {
  const screen = useScreenClass();
  const isSmall = ["sm", "xs"].includes(screen);
  const height = isSmall ? 146 : 104;
  const width = isSmall
    ? 126
    : "calc(25% - 20px)";
  const alignItems = isSmall
    ? "center"
    : "flex-start";
  const justifyContent = isSmall
    ? "center"
    : "space-between";
  const padding = isSmall ? 10 : 0;
  return (
    <CTAWithIconContainer
      to={url}
      style={{
        height,
        width,
        paddingLeft: padding,
        paddingRight: padding,
        alignItems: align,
      }}
    >
      <div
        style={{
          height,
          alignItems,
          justifyContent,
        }}
      >
        <div
          style={{
            flex: isSmall ? 0 : 1,
          }}
        >
          <img src={icon} alt={text} />
        </div>
        <H4
          style={{
            fontWeight: "bold",
            textAlign: isSmall
              ? "center"
              : "left",
          }}
        >
          {text}
        </H4>
      </div>
    </CTAWithIconContainer>
  );
};

const AboutCTA = () => {
  return (
    <Wrapper
      type="m"
      marginTop="185px"
      display="flex"
      justifyContent="space-between"
      style={{
        flexWrap: "wrap",
      }}
    >
      <CTAWithIcon
        text="Board of Directors"
        icon={bod}
        url="/about/board_of_directors"
        align="flex-start"
      />
      <CTAWithIcon
        text="AER Foundation"
        icon={af}
        url="/about/aea_foundation"
        align="center"
      />
      <CTAWithIcon
        text="State Committees"
        icon={ac}
        url="/about/state_committees"
        align="flex-end"
      />

      <CTAWithIcon
        text="Local Units"
        icon={lu}
        url="/about/local_units"
        align="flex-end"
      />
    </Wrapper>
  );
};

export default AboutCTA;
