import axios from "axios";
import { ENDPOINT } from "../utils/api";

const submitContact = (
  name: string,
  email: string,
  subject: string,
  message: string
): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    let form = new FormData();
    form.append("your-name", name);
    form.append("your-email", email);
    form.append("your-subject", subject);
    form.append("your-message", message);

    axios
      .post(
        `${ENDPOINT}/contact-form-7/v1/contact-forms/38/feedback`,
        form
      )
      .then((res) => {
        console.log(res);
        resolve(true);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};

export default submitContact;
