import styled, { css } from "styled-components";
import { Property } from "csstype";
import DS, {
  DSColorType,
  DSFontFamily,
} from "../DS";
export type BaseTypoProps = {
  fontFamily?: keyof DSFontFamily;
  fontColor?: keyof DSColorType;
  padding?: string | number;
  paddingLeft?: Property.Padding | number;
  paddingRight?: string | number;
  paddingTop?: string | number;
  paddingBottom?: string | number;
  margin?: Property.Margin | number;
  marginLeft?: string | number;
  marginRight?: string | number;
  marginTop?: string | number;
  marginBottom?: string | number;
};

const toPX = (value: string | number) => {
  if (typeof value === "number") {
    return `${value}px`;
  }

  return value;
};

const handleBlockParams = (data: {
  single?: string | number;
  left?: string | number;
  right?: string | number;
  top?: string | number;
  bottom?: string | number;
}) => {
  if (typeof data.single != "undefined") {
    return toPX(data.single);
  }
  let top = "0px";
  let right = "0px";
  let bottom = "0px";
  let left = "0px";
  if (typeof data.left != "undefined") {
    left = toPX(data.left);
  }
  if (typeof data.right != "undefined") {
    right = toPX(data.right);
  }
  if (typeof data.top != "undefined") {
    top = toPX(data.top);
  }
  if (typeof data.bottom != "undefined") {
    bottom = toPX(data.bottom);
  }

  return `${top} ${right} ${bottom} ${left}`;
};

export const BaseTypoCSS = (
  data?: BaseTypoProps
) => css<BaseTypoProps>`
  ${({
    fontFamily = data?.fontFamily ||
      "NeueHaasUnica",
    fontColor = data?.fontColor || "darkPurple",
    padding = data?.padding,
    paddingLeft = data?.paddingLeft,
    paddingRight = data?.paddingRight,
    paddingTop = data?.paddingTop,
    paddingBottom = data?.paddingBottom,
    margin = data?.margin,
    marginLeft = data?.marginLeft,
    marginRight = data?.marginRight,
    marginTop = data?.marginTop,
    marginBottom = data?.marginBottom,
  }) => `
    padding:${handleBlockParams({
      single: padding,
      left: paddingLeft,
      right: paddingRight,
      top: paddingTop,
      bottom: paddingBottom,
    })};
    margin:${handleBlockParams({
      single: margin,
      left: marginLeft,
      right: marginRight,
      top: marginTop,
      bottom: marginBottom,
    })};
    font-family: ${DS.fontFamily[fontFamily]};
    color:${DS.colors[fontColor]};
  `}
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

const BaseTypo = styled.p`
  ${BaseTypoCSS()}
`;

export default BaseTypo;
